const PolygonIcon = (props) => (
  <svg
    width={70}
    height={60}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: `scale(${window.outerWidth < 768 ? 0.5 : 1})` }}
    {...props}
  >
    <path
      d="M52.818 18.33c-1.292-.761-2.97-.761-4.391 0l-10.073 5.835-6.844 3.806-10.073 5.835c-1.291.76-2.97.76-4.39 0L9.04 29.239c-1.292-.761-2.196-2.157-2.196-3.679v-9.006c0-1.522.775-2.918 2.196-3.679l7.877-4.44c1.291-.76 2.97-.76 4.39 0l7.878 4.44c1.292.761 2.196 2.157 2.196 3.679v5.835l6.844-3.932v-5.835c0-1.523-.775-2.918-2.195-3.68L21.437.572c-1.291-.761-2.97-.761-4.39 0L2.194 8.943C.775 9.704 0 11.099 0 12.622v16.87c0 1.523.775 2.918 2.195 3.68l14.851 8.371c1.292.761 2.97.761 4.391 0l10.073-5.708 6.844-3.932 10.073-5.708c1.291-.762 2.97-.762 4.39 0l7.878 4.44c1.291.76 2.195 2.156 2.195 3.678v9.006c0 1.523-.774 2.918-2.195 3.679l-7.877 4.567c-1.292.76-2.97.76-4.391 0l-7.877-4.44c-1.292-.761-2.196-2.157-2.196-3.679v-5.835l-6.844 3.932v5.835c0 1.523.775 2.918 2.195 3.68l14.851 8.371c1.291.761 2.97.761 4.39 0l14.852-8.372c1.291-.76 2.195-2.156 2.195-3.679v-16.87c0-1.523-.775-2.918-2.195-3.68l-14.98-8.498Z"
      fill="#C2C2C2"
      fillOpacity={0.8}
    />
  </svg>
);

export default PolygonIcon;
