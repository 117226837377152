const PolygonIconDraw = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={184}
    height={100}
    {...props}
  >
    <g clipPath="url(#clip0_282_85)">
      <path clipRule="evenodd" d="M93.8217 1.82758C69.0122 1.82758 48.9012 21.9386 48.9012 46.748C48.9012 71.5575 69.0122 91.6685 93.8217 91.6685C118.631 91.6685 138.742 71.5575 138.742 46.748C138.742 21.9386 118.631 1.82758 93.8217 1.82758ZM93.8217 82.6637C73.9845 82.6637 57.9061 66.5853 57.9061 46.748C57.9061 26.9108 73.9919 10.8398 93.8217 10.8398C113.652 10.8398 129.737 26.9182 129.737 46.7554C129.737 66.5927 113.659 82.6637 93.8217 82.6637Z" fill="#E2A23B" />
      <path d="M105.203 39.6089C104.391 39.1269 103.336 39.1269 102.443 39.6089L96.1106 43.3045L91.8081 45.7147L85.476 49.4102C84.6642 49.8922 83.6088 49.8922 82.7159 49.4102L77.6827 46.5179C76.8708 46.0359 76.3026 45.1523 76.3026 44.1882V38.4841C76.3026 37.5201 76.7897 36.6364 77.6827 36.1543L82.6347 33.3425C83.4465 32.8605 84.5018 32.8605 85.3948 33.3425L90.3469 36.1543C91.1586 36.6364 91.7269 37.5201 91.7269 38.4841V42.1797L96.0295 39.6892V35.9937C96.0295 35.0296 95.5425 34.1459 94.6495 33.6639L85.476 28.3615C84.6642 27.8795 83.6088 27.8795 82.7159 28.3615L73.3801 33.6639C72.4871 34.1459 72 35.0296 72 35.9937V46.6787C72 47.6427 72.4871 48.5265 73.3801 49.0085L82.7159 54.3108C83.5277 54.7929 84.583 54.7929 85.476 54.3108L91.8081 50.6956L96.1106 48.2051L102.443 44.5899C103.255 44.1078 104.31 44.1078 105.203 44.5899L110.155 47.4017C110.967 47.8837 111.535 48.7675 111.535 49.7316V55.4355C111.535 56.3997 111.048 57.2833 110.155 57.7653L105.203 60.6576C104.391 61.1396 103.336 61.1396 102.443 60.6576L97.4908 57.8457C96.6789 57.3637 96.1106 56.4799 96.1106 55.5159V51.8204L91.8081 54.3108V58.0063C91.8081 58.9704 92.2952 59.8542 93.1881 60.3362L102.524 65.6385C103.336 66.1205 104.391 66.1205 105.284 65.6385L114.62 60.3362C115.432 59.8542 116 58.9704 116 58.0063V47.3213C116 46.3573 115.513 45.4737 114.62 44.9916L105.203 39.6089Z" fill="#E2A23B" />
      <path d="M56.3522 79.1343L59.2009 81.983L27.4584 98.4018L56.3522 79.1343Z" fill="#E2A23B" />
      <path d="M132.579 79.1343L129.737 81.983L161.48 98.4018L132.579 79.1343Z" fill="#E2A23B" />
      <path d="M140.777 31.5649C141.095 32.6156 141.361 33.6885 141.569 34.7836L146.593 32.4232L155.486 35.4199L183.33 9.40436L140.777 31.5649Z" fill="#E2A23B" />
      <path d="M182.997 8.87903L145.838 15.9453L143.278 24.9723L139.193 27.3622C139.652 28.3611 140.067 29.3896 140.422 30.4403L182.997 8.87903Z" fill="#E2A23B" />
      <path d="M44.5948 32.6008L44.913 31.4244C44.9278 31.3652 44.95 31.306 44.9648 31.2468L0.739868 12.7636L30.7066 36.367L39.3266 32.6304L44.2471 34.458L44.4691 33.0596L44.5948 32.6008Z" fill="#E2A23B" />
      <path d="M45.8897 28.3167L46.415 27.0884C46.4224 27.0736 46.4298 27.0514 46.4372 27.0366L42.0051 24.9205L38.6828 16.1302L1.021 12.2161L45.2903 30.1295L45.7047 28.7976L45.8897 28.3167Z" fill="#E2A23B" />
    </g>
    <defs>
      <clipPath id="clip0_282_85">
        <rect width="183.322" height="100" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PolygonIconDraw;
