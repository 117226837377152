import { useState, useEffect, useRef } from "react";
import { Container, Box, AppBar, Button, Grid, IconButton, Drawer, useMediaQuery, useTheme, makeStyles, Popper, Grow, Paper, ClickAwayListener, Fade } from "@material-ui/core";
import { Menu as MenuIcon, Close as CloseIcon } from "@material-ui/icons";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import LogoLarge from "assets/svgr/logotype/LogoLarge";
import LogoSmall from "assets/svgr/logotype/LogoSmall";
import links from "links";
import clsx from "clsx";
import ReactGA from "react-ga";
import DiscordIcon from "assets/svgr/social-media/DiscordIcon";

const useStyles = makeStyles((theme) => ({
  navBar: {
    height: 70,
  },
  menu: {
    background: "#121314",
    width: 170,
    padding: 10,
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      background: "transparent",
      marginTop: 0,
    },
  },
  menuItem: {
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginBottom: 10,
    },
  },
  closeIcon: {
    marginTop: 32,
    transition: "ease-out .2s",
  },
  closeTranslate: {
    transform: "translateY(120px)",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      background: "#121314",
    },
  },
  arrowUp: {
    position: "fixed",
    right: 90,
    bottom: 30,
    zIndex: 1000,
    background: theme.palette.primary.main,
    padding: 7,
    transition: ".2s ease-in-out!important",
    "&:hover": {
      background: `${theme.palette.primary.main}c0`,
    },
  },
  discord: {
    position: "fixed",
    right: 30,
    bottom: 25,
    zIndex: 1000,
    padding: 7,
    transition: ".2s ease-in-out!important",
    "&:hover": {
      background: "rgba(0,0,0,0.8)",
    },
  },
}));

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const topbar = useRef(null);
  const [drawer, setDrawer] = useState(false);
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);
  const [arrowUp, setArrowUp] = useState(false);

  const handleToggleMenu = () => setMenuOpen((prevOpen) => !prevOpen);

  const handleCloseMenu = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setMenuOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setMenuOpen(false);
    } else if (event.key === "Escape") {
      setMenuOpen(false);
    }
  };

  const prevOpen = useRef(menuOpen);

  useEffect(() => {
    if (prevOpen.current === true && menuOpen === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = menuOpen;
  }, [menuOpen]);

  useEffect(() => {
    const onScroll = (e) => {
      if (e.target.scrollingElement.scrollTop > 0) {
        if (!topbar.current.classList.contains("topbar-reduce")) { topbar.current.classList.add("topbar-reduce"); }
      } else if (topbar.current.classList.contains("topbar-reduce")) topbar.current.classList.remove("topbar-reduce");
      if (e.target.scrollingElement.scrollTop > (window.innerHeight / 2)) {
        setArrowUp(true);
      } else {
        setArrowUp(false);
      }
    };

    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  });

  const topPage = () => {
    document.scrollingElement.scrollIntoView({ behavior: "smooth", top: 0 });
  };

  const goUrl = (url) => {
    setDrawer(false);
    setTimeout(() => {
      document.getElementById(url).scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const handleDrawer = () => setDrawer((state) => !state);

  const goDiscord = () => {
    ReactGA.event({
      category: "Discord",
      action: "click",
      label: "join discord",
    });
    window.open(links.DISCORD, "_blank");
  };

  const goWebapp = () => {
    window.open(links.WEBAPP, "_blank");
  };

  const displayActions = () => (
    <Button
      color="primary"
      variant={isMobile ? "contained" : "outlined"}
      onClick={goDiscord}
      style={isMobile ? { padding: 5 } : { padding: "5px 15px" }}
    >
      Join Terrae
    </Button>
  );

  const displayLogo = () => (
    <>
      <LogoLarge style={{
        transform: "scale(0.6)",
        transformOrigin: "left center",
        minWidth: 110,
        overflow: "visible",
      }}
      />
      {isMobile
          && (
            <Box display="flex" alignItems="center" style={{ columnGap: 16 }}>
              {displayActions()}
              <IconButton {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawer,
              }}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
            </Box>
          )}
    </>
  );

  const displayRoutes = () => {
    const items = [
      { name: "Protocol", url: () => goUrl("protocol") },
      { name: "Roadmap", url: () => goUrl("roadmap") },
      { name: "Documentation",
        url: "Documentation",
        menu: [
          {
            name: "One Pager",
            url: () => window.open(links.ONEPAGER, "_blank"),
          },
          {
            name: "Whitepaper",
            url: () => window.open(links.WHITEPAPER, "_blank"),
          },
          {
            name: "Gitbook",
            url: () => window.open(links.DOCUMENTATION, "_blank"),
          },
        ],
      },
    ];

    return (
      <Box>
        <Grid container spacing={isMobile ? 2 : 4} direction={isMobile ? "column" : "row"} alignItems="center">
          {items.map((item, i) => (
            <Grid key={i} item>
              {item.menu ? (
                <Button
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={menuOpen ? "composition-menu" : undefined}
                  aria-expanded={menuOpen ? "true" : undefined}
                  aria-haspopup="true"
                  color="secondary"
                  variant="text"
                  onClick={handleToggleMenu}
                >
                  {item.name}
                </Button>
              ) : (
                <Button
                  color="secondary"
                  variant="text"
                  onClick={item.url}
                >
                  {item.name}
                </Button>
              )}
              {item.menu
                && (
                  <Popper
                    open={menuOpen}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement={isMobile ? "bottom" : "bottom-start"}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom-start" ? "left top" : "left bottom",
                        }}
                      >
                        <Paper className={classes.menu} elevation={0}>
                          <ClickAwayListener onClickAway={handleCloseMenu}>
                            <Box
                              autoFocusItem={menuOpen}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                              display="flex"
                              flexDirection="column"
                            >
                              {item.menu.map((menu, j) => (
                                <Button
                                  color="secondary"
                                  variant="text"
                                  onClick={menu.url}
                                  key={j}
                                  // onClick={handleCloseMenu}
                                  className={classes.menuItem}
                                >
                                  {menu.name}
                                </Button>
                              ))}
                            </Box>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                )}
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  return (
    <>
      <AppBar ref={topbar} color="transparent" elevation={0}>
        {!isMobile ? (
          <Container maxWidth="lg" className={classes.navBar}>
            <Box display="flex" alignItems="center" height={1}>
              {displayLogo()}
              <Box flexGrow={1} />
              {displayRoutes()}
              <Box flexGrow={1} />
              {displayActions()}
            </Box>
          </Container>
        ) : (
          <Container maxWidth="lg" display="flex" className={classes.navBar}>
            <Box display="flex" alignItems="center" height={1} justifyContent="space-between">
              {displayLogo()}
              <Drawer
                className={classes.drawer}
                {...{
                  anchor: "right",
                  open: drawer,
                  onClose: handleDrawer,
                }}
              >
                <Container>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    flex={1}
                    width={250}
                  >
                    <Box mt={4}>
                      <LogoSmall />
                    </Box>
                    {displayRoutes()}
                    <IconButton
                      onClick={handleDrawer}
                      className={clsx(classes.closeIcon, { [classes.closeTranslate]: menuOpen })}
                    >
                      <CloseIcon
                        fontSize="large"
                        style={{
                          border: "solid 1px",
                          borderRadius: "100%",
                          padding: 5,
                        }}
                        color="secondary"
                      />
                    </IconButton>
                  </Box>
                </Container>
              </Drawer>
            </Box>
          </Container>
        )}
      </AppBar>
      <Fade in={arrowUp}>
        <div>
          <IconButton className={classes.arrowUp} onClick={topPage}>
            <ArrowUpwardIcon style={{ color: theme.palette.background.default }} />
          </IconButton>
          <IconButton
            className={classes.discord}
            onClick={() => window.open(links.DISCORD, "_blank")}
          >
            <DiscordIcon />
          </IconButton>
        </div>
      </Fade>
    </>
  );
};
export default Header;
