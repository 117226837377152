const DividerTop = (props) => (
  <svg
    width={468}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: `scale(${window.outerWidth < 768 ? 0.5 : 1})` }}
    {...props}
  >
    <path
      d="M194.848 9.79H1.49a1.41 1.41 0 010-2.797h193.358a1.41 1.41 0 010 2.798z"
      fill="url(#prefix__paint0_linear_142:2814)"
    />
    <path
      d="M458.262 9.79H264.998a1.41 1.41 0 010-2.797h193.358a1.41 1.41 0 010 2.798h-.094z"
      fill="url(#prefix__paint1_linear_142:2814)"
    />
    <path
      d="M203.382 14.68a20.108 20.108 0 003.996 1.552c1.381.413 2.814.626 4.255.634 1.457.01 2.906-.196 4.302-.61a27.177 27.177 0 004.044-1.576 20.428 20.428 0 003.667-2.35 16.977 16.977 0 003.127-3.104 9.116 9.116 0 001.128-1.974 5.059 5.059 0 00.447-2.351 3.669 3.669 0 00-.987-2.351 4 4 0 00-2.14-1.34 3.547 3.547 0 00-1.316 0c-.407.073-.802.2-1.176.376a10.15 10.15 0 00-1.927 1.27 33.576 33.576 0 00-3.009 3.079c-.941 1.058-1.881 2.116-2.821 3.127a12.179 12.179 0 01-3.151 2.562 14.922 14.922 0 01-3.831 1.41 16.08 16.08 0 01-4.114.377 6.466 6.466 0 01-3.903-1.27c.97 1.05 2.126 1.91 3.409 2.54zm-1.622-1.34c.67.236 1.364.402 2.069.494 1.427.2 2.875.2 4.302 0 1.44-.222 2.842-.65 4.161-1.27a13.339 13.339 0 003.502-2.609c2.069-1.975 3.832-4.208 5.901-5.948a4.184 4.184 0 013.338-1.27 3.006 3.006 0 011.387.894c.38.404.605.928.635 1.481a5.503 5.503 0 01-1.293 3.432 17.074 17.074 0 01-2.797 2.892 23.039 23.039 0 01-7.218 3.879 15.415 15.415 0 01-3.996.776 16.202 16.202 0 01-4.114-.4 21.04 21.04 0 01-3.973-1.222 12.782 12.782 0 01-3.573-2.21c.475.472 1.044.84 1.669 1.081z"
      fill="url(#prefix__paint2_linear_142:2814)"
    />
    <path
      d="M195.507 7.228c1.575 1.716 2.915 3.385 4.537 4.843a24.9 24.9 0 005.266 3.738 21.992 21.992 0 006.018 2.186l.799.165.799.117 1.599.188h3.221a23.084 23.084 0 0011.989-3.973c3.667-2.35 7.053-5.125 10.837-7.617a27.263 27.263 0 016.089-3.173 12.414 12.414 0 0112.883 3.009 8.814 8.814 0 011.152 10.062 7.411 7.411 0 01-2.351 2.727 5.83 5.83 0 01-3.573 1.01h-.917a4.12 4.12 0 01-.894-.211 6.208 6.208 0 01-4.043-3.597 5.855 5.855 0 01-.165-3.644 4.7 4.7 0 012.163-2.985 3.48 3.48 0 011.857-.47c.624.011 1.24.147 1.811.4a.47.47 0 01-.353.869 3.104 3.104 0 00-2.774.14 3.832 3.832 0 00-1.552 2.352c-.241.97-.167 1.99.212 2.915a4.443 4.443 0 001.881 2.163c.869.502 1.842.8 2.844.87a4.42 4.42 0 002.727-.8 7.46 7.46 0 002.892-5.148 7.9 7.9 0 00-.235-3.056 7.426 7.426 0 00-1.434-2.727 10.37 10.37 0 00-5.431-3.174 9.873 9.873 0 00-6.253.54 23.044 23.044 0 00-5.431 3.503c-1.716 1.387-3.338 2.892-5.078 4.326a46.967 46.967 0 01-5.477 4.044c-.987.61-2.022 1.151-3.056 1.669-1.07.476-2.169.885-3.291 1.222-2.24.676-4.574.986-6.912.917a33.75 33.75 0 01-3.432-.141l-1.716-.259-.847-.117-.846-.188a24.342 24.342 0 01-6.465-2.351 28.006 28.006 0 01-5.618-3.997c-.87-.752-1.646-1.575-2.351-2.35-.705-.776-1.528-1.67-2.351-2.351a.942.942 0 010-1.317.947.947 0 01.67-.28.938.938 0 01.67.28l-.07-.329z"
      fill="url(#prefix__paint3_linear_142:2814)"
    />
    <path
      d="M197.904 11.201a11.014 11.014 0 002.986 2.092c1.075.572 2.2 1.044 3.362 1.41a21.881 21.881 0 007.052 1.082h1.787c.6-.024 1.197-.095 1.786-.211l1.74-.353 1.716-.54a22.515 22.515 0 006.206-3.41c.494-.352.941-.681 1.434-1.08l1.482-1.176a55.518 55.518 0 013.103-2.14 50.195 50.195 0 016.653-3.573 39.956 39.956 0 017.193-2.468 28.705 28.705 0 017.664-.823c1.315.033 2.624.207 3.902.517a15.03 15.03 0 013.785 1.41 14.34 14.34 0 015.807 5.549 17.571 17.571 0 011.622 3.667c.398 1.305.619 2.657.658 4.02a13.318 13.318 0 01-2.21 7.758 15.912 15.912 0 01-5.947 5.313c-1.199.6-2.46 1.065-3.762 1.387-1.31.293-2.654.411-3.996.352a13.178 13.178 0 01-3.949-.87A12.395 12.395 0 01244.522 27a14.94 14.94 0 01-4.208-6.582.873.873 0 01.552-1.007.87.87 0 011.07.419 14.095 14.095 0 003.926 5.571c.888.673 1.87 1.212 2.915 1.599 1.046.353 2.14.543 3.244.564a13.235 13.235 0 0011.308-6.089 10.392 10.392 0 001.316-3.009c.098-.256.162-.525.188-.799a7.74 7.74 0 00.141-.8c.035-.54.035-1.081 0-1.621-.038-1.1-.22-2.191-.54-3.245-.212-.54-.377-1.057-.612-1.575a11.147 11.147 0 00-.775-1.48 11.757 11.757 0 00-4.702-4.561 15.162 15.162 0 00-6.489-1.67 26.046 26.046 0 00-7.052.635 38.955 38.955 0 00-6.818 2.163 47.18 47.18 0 00-6.464 3.197 66.196 66.196 0 00-3.033 1.951c-.494.33-.987.706-1.481 1.058-.494.353-1.035.706-1.552 1.058a23.942 23.942 0 01-6.841 3.315l-1.833.494-1.905.282a12.43 12.43 0 01-1.88.14h-1.904a22.655 22.655 0 01-7.288-1.645 22.243 22.243 0 01-3.338-1.693 10.988 10.988 0 01-2.563-2.468z"
      fill="url(#prefix__paint4_linear_142:2814)"
    />
    <path
      d="M344.315 8.31a2.846 2.846 0 001.011 2.986 7.664 7.664 0 013.715 4.184s2.35-1.599.846-3.386c-1.505-1.786-4.255-1.246-5.572-3.785z"
      fill="url(#prefix__paint5_linear_142:2814)"
    />
    <path
      d="M344.315 8.38a2.846 2.846 0 011.011-2.986 7.664 7.664 0 003.715-4.184s2.35 1.599.846 3.385c-1.505 1.787-4.255 1.246-5.572 3.785z"
      fill="url(#prefix__paint6_linear_142:2814)"
    />
    <path
      d="M458.332 8.31a2.84 2.84 0 001.011 2.985 7.662 7.662 0 013.714 4.185s2.351-1.599.847-3.386c-1.505-1.786-4.256-1.246-5.572-3.785z"
      fill="url(#prefix__paint7_linear_142:2814)"
    />
    <path
      d="M458.332 8.38a2.84 2.84 0 011.011-2.985 7.662 7.662 0 003.714-4.185s2.351 1.599.847 3.385c-1.505 1.787-4.256 1.246-5.572 3.785z"
      fill="url(#prefix__paint8_linear_142:2814)"
    />
    <path
      d="M467.172 8.357c-1.293 0-3.245 1.95-6.066 1.551-2.351-.329-2.868-1.575-2.868-1.575s.517-1.222 2.845-1.551c2.938-.4 4.913 1.575 6.089 1.575z"
      fill="url(#prefix__paint9_linear_142:2814)"
    />
    <path
      d="M120.091 8.38a2.84 2.84 0 00-1.011-2.985 7.757 7.757 0 01-3.714-4.185s-2.351 1.599-.847 3.385c1.505 1.787 4.279 1.246 5.572 3.785z"
      fill="url(#prefix__paint10_linear_142:2814)"
    />
    <path
      d="M120.091 8.31a2.84 2.84 0 01-1.011 2.985 7.757 7.757 0 00-3.714 4.185s-2.351-1.599-.847-3.386c1.505-1.786 4.279-1.246 5.572-3.785z"
      fill="url(#prefix__paint11_linear_142:2814)"
    />
    <path
      d="M6.074 8.38a2.844 2.844 0 00-1.01-2.985A7.664 7.664 0 011.349 1.21S-1 2.81.503 4.595C2.007 6.382 4.78 5.841 6.074 8.38z"
      fill="url(#prefix__paint12_linear_142:2814)"
    />
    <path
      d="M6.074 8.31a2.845 2.845 0 01-1.01 2.985 7.664 7.664 0 00-3.715 4.185S-1 13.88.503 12.094c1.504-1.786 4.278-1.246 5.571-3.785z"
      fill="url(#prefix__paint13_linear_142:2814)"
    />
    <defs>
      <linearGradient
        id="prefix__paint0_linear_142:2814"
        x1={8874.62}
        y1={-503.863}
        x2={25211.5}
        y2={-503.863}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint1_linear_142:2814"
        x1={31079.3}
        y1={-503.863}
        x2={47416.1}
        y2={-503.863}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint2_linear_142:2814"
        x1={3899.22}
        y1={-2864.66}
        x2={4242.06}
        y2={-2864.66}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint3_linear_142:2814"
        x1={8855.13}
        y1={-3188.42}
        x2={10811.8}
        y2={-3188.42}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint4_linear_142:2814"
        x1={9252.85}
        y1={-5394.44}
        x2={11337.2}
        y2={-5394.44}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint5_linear_142:2814"
        x1={1527.18}
        y1={-1290.27}
        x2={1543.29}
        y2={-1290.27}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint6_linear_142:2814"
        x1={1527.18}
        y1={-1318.95}
        x2={1543.29}
        y2={-1318.95}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint7_linear_142:2814"
        x1={1940.47}
        y1={-1290.27}
        x2={1956.58}
        y2={-1290.27}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint8_linear_142:2814"
        x1={1940.47}
        y1={-1318.95}
        x2={1956.58}
        y2={-1318.95}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint9_linear_142:2814"
        x1={2604.36}
        y1={-583.834}
        x2={2638.31}
        y2={-583.834}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint10_linear_142:2814"
        x1={692.695}
        y1={-1318.95}
        x2={708.862}
        y2={-1318.95}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint11_linear_142:2814"
        x1={692.695}
        y1={-1290.27}
        x2={708.862}
        y2={-1290.27}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint12_linear_142:2814"
        x1={279.404}
        y1={-1318.95}
        x2={295.571}
        y2={-1318.95}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint13_linear_142:2814"
        x1={279.404}
        y1={-1290.27}
        x2={295.571}
        y2={-1290.27}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
    </defs>
  </svg>
);

export default DividerTop;
