import React, { useCallback, useEffect, useState } from "react";
import { Button, Container, Divider, Grid, makeStyles, Paper, Typography, Box } from "@material-ui/core";
import ConfirmTransactionDialog from "components/Dialogs/ConfirmTransaction";
import VerticalArragement from "components/VerticalArragement";
import HorizontalArragement from "components/HorizontalArragement";
import AirdropABI from "abi/Airdrop.json";
import ERC20ABI from "abi/ERC20.json";

import { useDialogs } from "store/Dialogs";
import { ethers, BigNumber } from "ethers";
import { chopString, getLeavesFromWhitelist, createLeaf } from "utils";
import BN from "bignumber.js";
import whitelist1 from "whitelists/whitelist1.json";
import whitelist2 from "whitelists/whitelist2.json";
import whitelist3 from "whitelists/whitelist3.json";
import whitelist4 from "whitelists/whitelist4.json";

import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";

/* eslint-disable func-names */
BigNumber.prototype.toBN = function () {
  return BN(this.toString());
};

BN.prototype.toEthersBN = function () {
  return BigNumber.from(this.toFixed(0));
};

BN.prototype.scaleUp = function (decimals) {
  return this.times(BN(10).pow(decimals));
};

BN.prototype.scaleDown = function (decimals) {
  return this.div(BN(10).pow(decimals));
};

const useStyles = makeStyles((theme) => ({
  innerContainer: {
    padding: theme.spacing(2),
  },
  paperContainer: {
    padding: theme.spacing(3),
    backgroundColor: "#121314",
    opacity: 0.8,
    marginTop: 20,
  },
  balanceContainer: {
    paddingTop: theme.spacing(3),
    backgroundColor: "transparent",
    opacity: 0.8,
    paperShadow: false,
    elevation: 0,
  },
  bgMain: {
    background: "linear-gradient(0deg, #0001 0%, #000c 100%), url(/bg-claim.jpg) no-repeat center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
  },
}));

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID || 137;
const JSON_RPC = process.env.REACT_APP_JSON_RPC || "https://polygon-rpc.com";
const EXPLORER = process.env.REACT_APP_EXPLORER || "https://polygonscan.com/";
const DENARIS_ADDRESS = process.env.REACT_APP_DENARIS || "0x993e6BC4B37819EcEeC9223DcCF311fD74f73cC6";

const AIRDROP_ADDRESS_1 = process.env.REACT_APP_AIRDROP_1 || "0xC5f8DEfF748290bE992fCc1E9C3b2f7F27eDd405";
const AIRDROP_ADDRESS_2 = process.env.REACT_APP_AIRDROP_2 || "0xd1BfE56Cd20685a80308A8e155ADb43FB890741D";
const AIRDROP_ADDRESS_3 = process.env.REACT_APP_AIRDROP_3 || "0x964A9E0d44D3A32B976db122E1d2279742f534b0";
const AIRDROP_ADDRESS_4 = process.env.REACT_APP_AIRDROP_4 || "0x21aDa18Eb51551587fAd7f3959d95e14EBbc4265";
const airdropsArrayDefaultData = [
  {
    name: "Presale, Airdrop 1 & Airdrop 2",
    description: "Thanks for your early support!",
    acquired: BN(0),
    available: BN(0),
    locked: BN(0),
    address: AIRDROP_ADDRESS_1,
    whitelist: whitelist1,
  },
  {
    name: "Airdrop 3",
    description: "Thanks for your early support!",
    acquired: BN(0),
    available: BN(0),
    locked: BN(0),
    address: AIRDROP_ADDRESS_2,
    whitelist: whitelist2,
  },
  {
    name: "Xmas Giveaway",
    description: "Enjoy your Christmas present!",
    acquired: BN(0),
    available: BN(0),
    locked: BN(0),
    address: AIRDROP_ADDRESS_3,
    whitelist: whitelist3,
  },
  {
    name: "Airdrop 4",
    description: "Thanks for your early support!",
    acquired: BN(0),
    available: BN(0),
    locked: BN(0),
    address: AIRDROP_ADDRESS_4,
    whitelist: whitelist4,
  },
];

const Airdrop = () => {
  const classes = useStyles();
  const dialogs = useDialogs();

  const [balance, setBalance] = useState(BN(0));

  const [accountProvider, setAccountProvider] = useState(null);
  const [selectedAccountAddress, setAddress] = useState(null);

  const [airdropsArray, setAirdropsArray] = useState(airdropsArrayDefaultData);

  const updateAccountValues = useCallback(async () => {
    const signer = await accountProvider.getSigner();
    const address = await signer.getAddress();
    setAddress(address.toLowerCase());
    const IERC20 = new ethers.Contract(DENARIS_ADDRESS, ERC20ABI, accountProvider);
    
    IERC20.balanceOf(address)
      .then((b) => setBalance(b.toBN()))
      .catch(() => null);

    const updatePromises = [];
    airdropsArray.forEach((airdrop, index) => {
      const Airdrop = new ethers.Contract(airdrop.address, AirdropABI, accountProvider);
      const acquired = BN(airdrop.whitelist[address.toLowerCase()] || 0);
      airdropsArrayDefaultData[index].acquired = acquired;

      updatePromises.push(Airdrop.getCurrentClaimableAmount(address, acquired.toFixed())
        .then((available) => {
          // console.log(`available: ${available}`);
          airdropsArrayDefaultData[index].available = available.toBN();
        }));
      updatePromises.push(Airdrop.getCurrentLockedAmount(address, acquired.toFixed())
        .then((locked) => {
          // console.log(`locked: ${locked}`);
          airdropsArrayDefaultData[index].locked = locked.toBN();
        }));
    });

    Promise.all(updatePromises).then(setAirdropsArray(airdropsArrayDefaultData));
  });

  useEffect(() => {
    let interval;
    if (accountProvider) {
      updateAccountValues();
      interval = setInterval(() => {
        updateAccountValues();
      }, 5000);
    }
    return () => {
      if (interval)clearInterval(interval);
    };
  }, [accountProvider]);

  useEffect(() => {
    if (!window.ethereum) {
      dialogs.create({
        title: "Metamask extension required",
        subtitle: "This is a dApp, you need metamask in order to interact with it",
        actions: [{ text: "Install Metamask", onClick: () => window.open("https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=es", "_blank") }],
      });
    // } else if (!purchased) {
    // } else if (airdropsArray.filter((el) => el.acquired > BN(0)).length === 0) {
    //   dialogs.create({
    //     title: "You don't have tokens to claim",
    //     subtitle: "If you think this is an issue, please contact us in Discord",
    //   });
    }
  }, []);

  const setupMetamask = useCallback(async () => {
    setAccountProvider(null);
    setAirdropsArray(airdropsArrayDefaultData);

    const { ethereum } = window;
    const prov = new ethers.providers.Web3Provider(window.ethereum, "any");
    await ethereum.request({ method: "eth_requestAccounts" });
    const chainId = await ethereum.request({ method: "eth_chainId" });
    if (Number(chainId) !== CHAIN_ID) {
      const dialog = dialogs.create({
        title: "Invalid network",
        subtitle: "Change the network to POLYGON (MATIC) mainnet",
        actions: [{
          text: "Change network",
          onClick: async () => {
            try {
              await ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: ethers.utils.hexValue(CHAIN_ID) }],
              });
            } catch (switchError) {
              // This error code indicates that the chain has not been added to MetaMask.
              if (switchError.code === 4902) {
                try {
                  await ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: [{ chainId: ethers.utils.hexValue(CHAIN_ID), rpcUrl: JSON_RPC }],
                  });
                } catch (addError) {
                  await ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: [{
                      chainId: ethers.utils.hexValue(CHAIN_ID),
                      rpcUrls: [JSON_RPC],
                      chainName: "Polygon (MATIC)",
                      blockExplorerUrls: [EXPLORER],
                    }],
                  });
                  // handle "add" error
                }
              }
            }
            dialog.close();
          },
        }],
      });
      return;
    }

    setAccountProvider(prov);
  }, []);

  const onClaim = async (airdrop) => {
    const Airdrop = new ethers.Contract(airdrop.address, AirdropABI, accountProvider);

    const signer = await accountProvider.getSigner();
    const address = await signer.getAddress();

    const leaves = getLeavesFromWhitelist(airdrop.whitelist);
    const merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true });
    const proof = merkleTree.getHexProof(createLeaf(
      address,
      airdrop.acquired.toEthersBN(),
    ));

    const tx = await Airdrop.populateTransaction.claim(airdrop.acquired.toEthersBN(), proof);

    const unsignedTx = {
      to: airdrop.address,
      value: 0,
      data: tx.data,
      from: address,
      chainId: CHAIN_ID,
    };

    try {
      const txHash = await accountProvider.provider.request({
        method: "eth_sendTransaction",
        params: [unsignedTx],
      });

      if (txHash) {
        const dialog = dialogs.create({
          DialogType: ConfirmTransactionDialog,
          txHash,
          explorer: EXPLORER,
          provider: accountProvider,
          onClose: () => {
            setupMetamask();
            dialog.close();
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getOnClaim = (airdrop) => () => onClaim(airdrop);

  const connectWallet = useCallback(async () => {
    await setupMetamask();
    const { ethereum } = window;
    if (ethereum) {
      ethereum.on("accountsChanged", () => setupMetamask());
      ethereum.on("chainChanged", () => setupMetamask());
    }
  }, []);

  return (
    <VerticalArragement
      className={classes.bgMain}
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      width="100%"
      height="100%"
      overflow="auto"
    >
      <Container maxWidth="md">

        <Box className={classes.balanceContainer}>
          <Typography
            variant="h5"
            className="bold font-classic"
            color="secondary"
            align="right"
          >
            My Denaris Balance:
            {" "}
            {balance.scaleDown(18).toFixed(4)}
            {" "}
            DENI
          </Typography>
        </Box>

        {
          airdropsArray.map((airdrop, i) => (
            <Paper
              className={classes.paperContainer}
              key={i}
            >
              <VerticalArragement spacing={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" className="bold font-classic" color="secondary">
                      {airdrop.description}
                    </Typography>
                    <Typography color="primary" variant="h3" className="bold font-classic">
                      {airdrop.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper color="default" className={classes.innerContainer}>
                      <VerticalArragement>
                        
                        <HorizontalArragement justifyContent="space-between">
                          <Typography color="primary" className="bold font-classic">
                            Acquired
                          </Typography>
                          <Typography className="bold font-classic" color="secondary">
                            {airdrop.acquired ? airdrop.acquired.scaleDown(18).toFixed(4) : "0.00"}
                            {" "}
                            DENI
                          </Typography>
                        </HorizontalArragement>
                        
                        <HorizontalArragement justifyContent="space-between">
                          <Typography color="primary" className="bold font-classic">
                            Available to Claim
                          </Typography>
                          <HorizontalArragement>
                            <Typography className="bold font-classic" color="secondary">
                              {airdrop.available ? airdrop.available.scaleDown(18).toFixed(4) : "0.00"}
                              {" "}
                              DENI
                            </Typography>
                          </HorizontalArragement>
                        </HorizontalArragement>
                        
                        <HorizontalArragement justifyContent="space-between">
                          <Typography color="primary" className="bold font-classic">
                            Locked
                          </Typography>
                          <HorizontalArragement>
                            <Typography className="bold font-classic" color="secondary">
                              {airdrop.locked ? airdrop.locked.scaleDown(18).toFixed(4) : "0.00"}
                              {" "}
                              DENI
                            </Typography>
                          </HorizontalArragement>
                        </HorizontalArragement>
                        
                      </VerticalArragement>
                    </Paper>
                    {selectedAccountAddress && (
                      <Typography variant="caption">
                        Connected Account:
                        {" "}
                        {chopString(selectedAccountAddress, 20)}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Divider variant="middle" />

                <HorizontalArragement justifyContent="center">
                  {!accountProvider && (
                    <Button
                      onClick={connectWallet}
                      variant="contained"
                      color="primary"
                    >
                      Connect Wallet
                    </Button>
                  )}

                  {accountProvider && (
                    <Button
                      onClick={getOnClaim(airdrop)}
                      variant="outlined"
                      color="primary"
                      disabled={airdrop.available.eq(0)}
                    >
                      Claim
                    </Button>
                  )}
                </HorizontalArragement>
              </VerticalArragement>
            </Paper>
          ))
        }

        <Box className={classes.balanceContainer} />

      </Container>
    </VerticalArragement>
  );
};

export default Airdrop;
