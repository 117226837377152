import { ethers } from "ethers";

export const millisToTimeComponents = (ms) => {
  let h;
  let m;
  let s;
  s = Math.floor(ms / 1000);
  m = Math.floor(s / 60);
  s %= 60;
  h = Math.floor(m / 60);
  m %= 60;
  const d = Math.floor(h / 24);
  h %= 24;

  return {
    d,
    h,
    m,
    s,
  };
};

export const chopString = (str, length) => (str.length > length ? `${str.substring(0, length)}...` : str);

export const uploadFileToS3 = (preSignedUrl, file) => new Promise((res) => {
  const xhr = new XMLHttpRequest();
  xhr.open("PUT", preSignedUrl, true);
  xhr.onload = (data) => {
    if (xhr.status === 200) {
      res(data);
    } else res({ error: data });
  };
  xhr.onerror = (error) => {
    res({ error });
  };
  xhr.send(file); // `file` is a File object here
});

export const parseJsonOrNull = (str) => {
  try {
    return JSON.parse(str);
  } catch (_) {
    return null;
  }
};

export const createLeaf = (address, quantity) => Buffer.from(
  ethers.utils.solidityKeccak256(["address", "uint256"], [address, quantity]).slice(2),
  "hex",
);

export const getLeavesFromWhitelist = (whitelist) => Object.entries(whitelist)
  .sort(([keyA], [keyB]) => {
    if (keyA > keyB) return -1;
    if (keyB > keyA) return 1;
    return 0;
  })
  .map((entry) => createLeaf(...entry));

export const vwLess = (value) => `calc(100vw - ${value}px)`;
export const vwPlus = (value) => `calc(100vw + ${value}px)`;

export const vhLess = (value) => `calc(100vh - ${value}px)`;
export const vhPlus = (value) => `calc(100vh + ${value}px)`;
