const DiscordIcon = (props) => (
  <svg
    width={34}
    height={33}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.789 6.79c-.238-.66-.477-1.325-.482-1.334a.654.654 0 00-.657-.389c-.177.014-4.35.374-7.024 2.524-1.398 1.291-4.19 8.841-4.19 15.368 0 .116.03.228.087.328 1.929 3.386 7.185 4.273 8.383 4.31a.667.667 0 00.556-.271l1.296-1.755c-2.14-.325-2.527-.75-2.617-.816-.484-.356-.84-.855-.408-1.484.334-.489 1.033-.65 1.568-.375.55.256 2.018 1.08 6.379 1.058 4.33-.014 6.23-.92 6.258-.94.727-.328 1.295-.193 1.648.293.422.66.121 1.108-.36 1.463-.09.067-.31.253-2.609.797l1.28 1.759a.662.662 0 00.535.272l.021-.001c1.199-.038 6.456-.925 8.383-4.31a.653.653 0 00.087-.329c0-6.526-2.792-14.076-4.225-15.395-2.639-2.121-6.812-2.48-6.989-2.496a.662.662 0 00-.657.389c-.005.009-.238.685-.463 1.337 0 0-1.776-.26-2.91-.26-1.133 0-2.89.257-2.89.257zm-1.465 13.897c-1.203 0-2.177-1.451-2.177-3.243 0-1.793.974-3.244 2.177-3.244 1.208-.18 2.152 1.451 2.178 3.244 0 1.792-.975 3.243-2.178 3.243zm8.71 0c-1.202 0-2.177-1.461-2.177-3.263 0-1.802.975-3.263 2.178-3.263 1.203 0 2.177 1.461 2.177 3.263 0 1.802-.974 3.263-2.177 3.263z"
      fill="#E2A23B"
    />
  </svg>
);

export default DiscordIcon;
