import { Box, Button, Grid, Typography, Grow, Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const Banner = ({ goWebapp }) => {
  const router = useHistory();

  return (
    <Grow in timeout={1000}>
      <Container maxWidth="lg">
        <Grid item xs={12} sm={8} lg={6} xl={4}>
          <Box position="relative" zIndex={5}>
            <Typography variant="h4" color="secondary">Enter the Medieval World!</Typography>
            <Typography variant="h2" paragraph>
              TERRAE
            </Typography>
            <Typography variant="body1" color="secondary" paragraph>
              Gamifying DeFi with a medieval game.
              Swap in the DEX, trade tokens and NFTs, rise your empire
              and fight in battles to earn crypto!
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              onClick={goWebapp}
            >
              Join Terrae
            </Button>
            <Box ml={3} display="inline">
              <Button
                color="primary"
                variant="contained"
                onClick={() => router.push("/airdrop")}
              >
                Claim
              </Button>
            </Box>
          </Box>
        </Grid>
      </Container>
    </Grow>
  );
};

export default Banner;
