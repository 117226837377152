import { Box, Typography, Grid, makeStyles } from "@material-ui/core";
import SwiperCore, { Pagination, Mousewheel } from "swiper";
import Swiper from "react-id-swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Pagination, Mousewheel]);

const params = {
  centeredSlides: true,
  mousewheel: true,
  slidesPerView: 1,
  pagination: {
    el: ".swiper-pagination",
    clickable: false,
  },
};

const useStyles = makeStyles((theme) => ({
  boxHouse: {
    width: 380,
    backgroundColor: "#0008",
    boxSizing: "border-box",
    [theme.breakpoints.down("xs")]: {
      width: 340,
    },
  },
  boxHouseInfo: {
    backgroundColor: "#000",
    width: "100%",
    "& .MuiTypography-root": {
      maxWidth: 250,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  boxSwiper: {
    width: "100%",
    "& .swiper-container": {
      margin: "0px auto",
      position: "relative",
      overflow: "hidden",
      listStyle: "none",
      padding: 0,
      paddingBottom: 30,
      zIndex: 1,
      height: "100%",
      width: "100%",
      "& $boxHouse": {
        margin: "auto",
      },
      "& .swiper-pagination-bullets": {
        bottom: 0,
      },
      "& .swiper-pagination-bullet": {
        background: theme.palette.primary.main,
      },
    },
  },
}));

export const House = ({ logo, title, info, ...boxArgs }) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" {...boxArgs} className={classes.boxHouse}>
      <Box py={4} display="flex" justifyContent="center" alignItems="center" minHeight={270}>
        <img src={logo} alt="" />
      </Box>
      <Box p={2} className={classes.boxHouseInfo}>
        <Box minHeight={32} mb={1}>
          <Typography variant="h5" align="center">
            {title}
          </Typography>
        </Box>
        <Typography variant="body2" color="secondary" align="center">
          {info}
        </Typography>
      </Box>
    </Box>
  );
};

export const TabletHouse = ({ houseData }) => (
  houseData.map((item, i) => (
    <Grid item key={i}>
      <House
        logo={`/logos/${item.logo}`}
        title={item.name}
        info={item.info}
      />
    </Grid>
  ))
);

export const MobileHouse = ({ houseData }) => {
  const classes = useStyles();
  return (
    <Box className={classes.boxSwiper}>
      <Swiper {...params}>
        <div>
          <House
            logo="/logos/larandel.png"
            title="House Larandel"
            info="Larandel is the legendary House of the first elves born on the Terrae."
          />
        </div>
        {houseData.map((item, i) => (
          <div key={i}>
            <House
              logo={`/logos/${item.logo}`}
              title={item.name}
              info={item.info}
            />
          </div>
        ))}
      </Swiper>
    </Box>
  );
};
