const MediumIcon = (props) => (
  <svg
    width={27}
    height={27}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#prefix__clip0_208_403)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.968 7.677a1.01 1.01 0 00-.33-.854l-2.44-2.94v-.439h7.577l5.855 12.843 5.148-12.843H27v.44l-2.086 2a.611.611 0 00-.232.585v14.697a.61.61 0 00.232.584l2.037 2v.44H16.703v-.44l2.111-2.049c.207-.207.207-.268.207-.584V9.237l-5.867 14.904h-.793L5.53 9.238v9.989c-.057.419.083.842.378 1.145l2.745 3.33v.44H.869v-.44l2.745-3.33c.294-.304.424-.73.354-1.145V7.677z"
        fill="#E2A23B"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0_208_403">
        <path
          fill="#fff"
          transform="translate(.869 .178)"
          d="M0 0h26.131v26.131H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default MediumIcon;
