const DividerTop = (props) => (
  <svg
    width={468}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: `scale(${window.outerWidth < 768 ? 0.5 : 1})` }}
    {...props}
  >
    <path
      d="M194.848 20.208H1.49a1.41 1.41 0 000 2.798h193.358a1.41 1.41 0 000-2.798z"
      fill="url(#prefix__paint0_linear_142:2799)"
    />
    <path
      d="M458.262 20.208H264.998a1.41 1.41 0 000 2.798h193.358a1.41 1.41 0 000-2.798h-.094z"
      fill="url(#prefix__paint1_linear_142:2799)"
    />
    <path
      d="M203.382 15.319a20.114 20.114 0 013.996-1.552 15.167 15.167 0 014.255-.634c1.457-.01 2.906.196 4.302.61 1.388.417 2.74.944 4.044 1.576a20.423 20.423 0 013.667 2.35 16.973 16.973 0 013.127 3.104 9.116 9.116 0 011.128 1.975 5.06 5.06 0 01.447 2.35 3.669 3.669 0 01-.987 2.351 4 4 0 01-2.14 1.34 3.547 3.547 0 01-1.316 0 4.706 4.706 0 01-1.176-.376 10.152 10.152 0 01-1.927-1.27 33.578 33.578 0 01-3.009-3.079c-.941-1.058-1.881-2.116-2.821-3.127a12.179 12.179 0 00-3.151-2.562 14.926 14.926 0 00-3.831-1.41 16.08 16.08 0 00-4.114-.377 6.466 6.466 0 00-3.903 1.27c.97-1.05 2.126-1.91 3.409-2.539zm-1.622 1.34c.67-.236 1.364-.402 2.069-.494 1.427-.2 2.875-.2 4.302 0 1.44.222 2.842.65 4.161 1.27a13.338 13.338 0 013.502 2.61c2.069 1.974 3.832 4.207 5.901 5.947a4.182 4.182 0 003.338 1.27 3.007 3.007 0 001.387-.894c.38-.403.605-.928.635-1.481a5.503 5.503 0 00-1.293-3.432 17.075 17.075 0 00-2.797-2.892 23.036 23.036 0 00-7.218-3.879 15.415 15.415 0 00-3.996-.775 16.202 16.202 0 00-4.114.4 21.033 21.033 0 00-3.973 1.222 12.78 12.78 0 00-3.573 2.21 4.838 4.838 0 011.669-1.082z"
      fill="url(#prefix__paint2_linear_142:2799)"
    />
    <path
      d="M195.507 22.771c1.575-1.716 2.915-3.385 4.537-4.843a24.897 24.897 0 015.266-3.738 21.992 21.992 0 016.018-2.186l.799-.164.799-.118 1.599-.188h3.221c4.288.184 8.44 1.56 11.989 3.973 3.667 2.35 7.053 5.125 10.837 7.617a27.263 27.263 0 006.089 3.173 12.414 12.414 0 0012.883-3.009 8.814 8.814 0 001.152-10.061 7.413 7.413 0 00-2.351-2.727 5.83 5.83 0 00-3.573-1.011h-.917a4.117 4.117 0 00-.894.211 6.209 6.209 0 00-4.043 3.597 5.855 5.855 0 00-.165 3.644 4.701 4.701 0 002.163 2.986 3.48 3.48 0 001.857.47 4.705 4.705 0 001.811-.4.47.47 0 00-.353-.87 3.103 3.103 0 01-2.774-.14 3.832 3.832 0 01-1.552-2.352 4.697 4.697 0 01.212-2.915 4.443 4.443 0 011.881-2.163 6.6 6.6 0 012.844-.87 4.419 4.419 0 012.727.8 7.46 7.46 0 012.892 5.148 7.901 7.901 0 01-.235 3.057 7.426 7.426 0 01-1.434 2.727 10.37 10.37 0 01-5.431 3.173 9.873 9.873 0 01-6.253-.54 23.043 23.043 0 01-5.431-3.503c-1.716-1.387-3.338-2.892-5.078-4.326a46.956 46.956 0 00-5.477-4.043c-.987-.612-2.022-1.152-3.056-1.67a27.659 27.659 0 00-3.291-1.222 21.706 21.706 0 00-6.912-.917 33.772 33.772 0 00-3.432.141l-1.716.259-.847.117-.846.188a24.342 24.342 0 00-6.465 2.351 28.003 28.003 0 00-5.618 3.997c-.87.752-1.646 1.575-2.351 2.35-.705.776-1.528 1.67-2.351 2.351a.942.942 0 000 1.317.948.948 0 00.67.28.94.94 0 00.67-.28l-.07.33z"
      fill="url(#prefix__paint3_linear_142:2799)"
    />
    <path
      d="M197.904 18.798a11.016 11.016 0 012.986-2.092 19.922 19.922 0 013.362-1.41 21.878 21.878 0 017.052-1.082h1.787c.6.024 1.197.095 1.786.211l1.74.353 1.716.54a22.512 22.512 0 016.206 3.41c.494.352.941.681 1.434 1.08l1.482 1.176a55.481 55.481 0 003.103 2.14 50.199 50.199 0 006.653 3.573 39.953 39.953 0 007.193 2.468c2.506.618 5.084.895 7.664.823a18.52 18.52 0 003.902-.517 15.03 15.03 0 003.785-1.41 14.34 14.34 0 005.807-5.549 17.57 17.57 0 001.622-3.667c.398-1.305.619-2.657.658-4.02a13.318 13.318 0 00-2.21-7.758 15.912 15.912 0 00-5.947-5.313 18.08 18.08 0 00-3.762-1.387 15.25 15.25 0 00-3.996-.352 13.182 13.182 0 00-3.949.87A12.396 12.396 0 00244.522 3a14.94 14.94 0 00-4.208 6.583.873.873 0 00.552 1.006.87.87 0 001.07-.419 14.094 14.094 0 013.926-5.571A11.352 11.352 0 01248.777 3a10.81 10.81 0 013.244-.564 13.234 13.234 0 0111.308 6.089c.595.927 1.04 1.942 1.316 3.009.098.257.162.525.188.8.061.263.108.53.141.799.035.54.035 1.081 0 1.622-.038 1.1-.22 2.19-.54 3.244-.212.54-.377 1.058-.612 1.575a11.15 11.15 0 01-.775 1.48 11.757 11.757 0 01-4.702 4.562 15.161 15.161 0 01-6.489 1.669 26.045 26.045 0 01-7.052-.635 38.954 38.954 0 01-6.818-2.163 47.178 47.178 0 01-6.464-3.197 66.206 66.206 0 01-3.033-1.951c-.494-.33-.987-.705-1.481-1.058-.494-.353-1.035-.705-1.552-1.058a23.941 23.941 0 00-6.841-3.315l-1.833-.493-1.905-.282a12.417 12.417 0 00-1.88-.142h-1.904a22.66 22.66 0 00-7.288 1.646 22.243 22.243 0 00-3.338 1.693 10.99 10.99 0 00-2.563 2.468z"
      fill="url(#prefix__paint4_linear_142:2799)"
    />
    <path
      d="M344.315 21.69a2.847 2.847 0 011.011-2.986 7.664 7.664 0 003.715-4.184s2.35 1.598.846 3.385c-1.505 1.786-4.255 1.246-5.572 3.785z"
      fill="url(#prefix__paint5_linear_142:2799)"
    />
    <path
      d="M344.315 21.619a2.847 2.847 0 001.011 2.986 7.664 7.664 0 013.715 4.184s2.35-1.599.846-3.385c-1.505-1.787-4.255-1.246-5.572-3.785z"
      fill="url(#prefix__paint6_linear_142:2799)"
    />
    <path
      d="M458.332 21.69a2.84 2.84 0 011.011-2.986 7.662 7.662 0 003.714-4.184s2.351 1.598.847 3.385c-1.505 1.786-4.256 1.246-5.572 3.785z"
      fill="url(#prefix__paint7_linear_142:2799)"
    />
    <path
      d="M458.332 21.619a2.84 2.84 0 001.011 2.985 7.662 7.662 0 013.714 4.185s2.351-1.599.847-3.385c-1.505-1.787-4.256-1.246-5.572-3.785z"
      fill="url(#prefix__paint8_linear_142:2799)"
    />
    <path
      d="M467.172 21.642c-1.293 0-3.245-1.95-6.066-1.551-2.351.33-2.868 1.575-2.868 1.575s.517 1.223 2.845 1.552c2.938.4 4.913-1.576 6.089-1.576z"
      fill="url(#prefix__paint9_linear_142:2799)"
    />
    <path
      d="M120.091 21.619a2.84 2.84 0 01-1.011 2.985 7.757 7.757 0 00-3.714 4.185s-2.351-1.599-.847-3.385c1.505-1.787 4.279-1.246 5.572-3.785z"
      fill="url(#prefix__paint10_linear_142:2799)"
    />
    <path
      d="M120.091 21.69a2.84 2.84 0 00-1.011-2.986 7.757 7.757 0 01-3.714-4.184s-2.351 1.598-.847 3.385c1.505 1.786 4.279 1.246 5.572 3.785z"
      fill="url(#prefix__paint11_linear_142:2799)"
    />
    <path
      d="M6.074 21.619a2.845 2.845 0 01-1.01 2.985 7.664 7.664 0 00-3.715 4.185S-1 27.19.503 25.404c1.504-1.787 4.278-1.246 5.571-3.785z"
      fill="url(#prefix__paint12_linear_142:2799)"
    />
    <path
      d="M6.074 21.69a2.845 2.845 0 00-1.01-2.986 7.664 7.664 0 01-3.715-4.184S-1 16.117.503 17.904C2.007 19.69 4.78 19.15 6.074 21.69z"
      fill="url(#prefix__paint13_linear_142:2799)"
    />
    <defs>
      <linearGradient
        id="prefix__paint0_linear_142:2799"
        x1={8874.62}
        y1={533.862}
        x2={25211.5}
        y2={533.862}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint1_linear_142:2799"
        x1={31079.3}
        y1={533.862}
        x2={47416.1}
        y2={533.862}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint2_linear_142:2799"
        x1={3899.22}
        y1={2894.66}
        x2={4242.06}
        y2={2894.66}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint3_linear_142:2799"
        x1={8855.13}
        y1={3218.42}
        x2={10811.8}
        y2={3218.42}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint4_linear_142:2799"
        x1={9252.85}
        y1={5424.44}
        x2={11337.2}
        y2={5424.44}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint5_linear_142:2799"
        x1={1527.18}
        y1={1320.27}
        x2={1543.29}
        y2={1320.27}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint6_linear_142:2799"
        x1={1527.18}
        y1={1348.94}
        x2={1543.29}
        y2={1348.94}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint7_linear_142:2799"
        x1={1940.47}
        y1={1320.27}
        x2={1956.58}
        y2={1320.27}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint8_linear_142:2799"
        x1={1940.47}
        y1={1348.94}
        x2={1956.58}
        y2={1348.94}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint9_linear_142:2799"
        x1={2604.36}
        y1={613.833}
        x2={2638.31}
        y2={613.833}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint10_linear_142:2799"
        x1={692.695}
        y1={1348.94}
        x2={708.862}
        y2={1348.94}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint11_linear_142:2799"
        x1={692.695}
        y1={1320.27}
        x2={708.862}
        y2={1320.27}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint12_linear_142:2799"
        x1={279.404}
        y1={1348.94}
        x2={295.571}
        y2={1348.94}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
      <linearGradient
        id="prefix__paint13_linear_142:2799"
        x1={279.404}
        y1={1320.27}
        x2={295.571}
        y2={1320.27}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#B27F2A" />
        <stop offset={0.17} stopColor="#B7862E" />
        <stop offset={0.22} stopColor="#C49939" />
        <stop offset={0.28} stopColor="#DAB84C" />
        <stop offset={0.33} stopColor="#F4DD62" />
        <stop offset={0.52} stopColor="#F4E38B" />
        <stop offset={0.66} stopColor="#F4DD62" />
        <stop offset={0.89} stopColor="#B27F2A" />
      </linearGradient>
    </defs>
  </svg>
);

export default DividerTop;
