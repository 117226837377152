import { Box, Button, Grid, Typography, Slide, Container, makeStyles, Hidden } from "@material-ui/core";
import { TabletHouse, MobileHouse, House } from "components/House";
import links from "links";
import InView from "react-intersection-observer";

const useStyles = makeStyles(() => ({
  boxTitleHouses: {
    display: "flex",
    flexDirection: "column",
    marginTop: 32,
    "& .MuiTypography-root": {
      maxWidth: 630,
    },
  },
}));
  
const Houses = ({ isSm, isMd }) => {
  const classes = useStyles();

  return (
    <Container>
      <InView triggerOnce delay={500} threshold={0.2}>
        {({ inView, ref }) => (
          <Box ref={ref}>
            <Slide in={inView} direction="up" {...(inView ? { timeout: 500 } : {})}>
              <Grid container>
                <Grid item xs={12} lg={7} className={classes.boxTitleHouses}>
                  <Typography variant="h4" paragraph>
                    Choose your House and participate in weekly Tournaments
                  </Typography>
                  <Typography variant="body2" color="secondary">
                    Will you fight for Taramos, the proud Man’s House? Will you
                    choose the wise Larandel elves or the powerful Baldor dwarfs?
                    Each House accumulates points in weekly fights and the winner earn rewards,
                    which is distributed between its members.
                  </Typography>
                  <Box mt={2}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => window.open(links.HOUSES, "_blank")}
                    >
                      View more
                    </Button>
                  </Box>
                </Grid>
                <Hidden mdDown>
                  <Grid item xs={12} lg={5}>
                    <House
                      ml="auto"
                      logo="/logos/larandel.png"
                      title="House Larandel"
                      info="Larandel is the legendary House of the first elves born on the Terrae."
                    />
                  </Grid>
                </Hidden>
              </Grid>
            </Slide>
          </Box>
        )}
      </InView>
      <InView triggerOnce delay={500} threshold={0.2}>
        {({ inView, ref }) => (
          <Box ref={ref} mt={10}>
            <Slide in={inView} direction="up" {...(inView ? { timeout: 500 } : {})}>
              <Grid container justifyContent={isMd ? "space-evenly" : "space-between"} spacing={2}>
                {(() => {
                  const houseData = [
                    {
                      logo: "baldor.png",
                      name: "House Baldor",
                      info: "Baldor is a House among Dwarfs of the mines and mountains.",
                    },
                    {
                      logo: "taramos.png",
                      name: "House Taramos",
                      info: "Taramos is the older House among proud and bravery Men.",
                    },
                    {
                      logo: "ask.png",
                      name: "",
                      info: "Token holders and LP holders will take votes on new Houses",
                    },
                  ];
                  return (
                    <>
                      <Hidden only={["xs", "sm", "lg", "xl"]}>
                        <Grid item>
                          <House
                            logo="/logos/larandel.png"
                            title="House Larandel"
                            info="Larandel is the legendary House of the first elves born on the Terrae."
                          />
                        </Grid>
                      </Hidden>
                      {isSm ? <MobileHouse houseData={houseData} />
                        : <TabletHouse houseData={houseData} />}
                    </>
                  );
                })()}
              </Grid>
            </Slide>
          </Box>
        )}
      </InView>
    </Container>
  );
};

export default Houses;
