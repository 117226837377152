import { Button, Grid, Typography, TextField, makeStyles, Box } from "@material-ui/core";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga";
import { ReCaptcha } from "react-recaptcha-google";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: theme.palette.text.subtitle,
    marginTop: -10,
    marginBottom: 20,
  },
  signupButton: {
    marginLeft: -5,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  knightBackground: {
    "& img": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      objectFit: "cover",
      filter: "brightness(0.75)",
      zIndex: -2,
      height: 530,
      objectPosition: "center",
    },
    zIndex: -1,
  },
  signupInput: {
    width: 290,
    height: 56,
    "& .MuiFormHelperText-root": {
      color: theme.palette.primary.main,
      ...theme.typography.subtitle2,
    },
  },
}));

const FormFields = ({ status, subscribe, handleSuccess, success }) => {
  const [email, setEmail] = useState("");
  const [text, setText] = useState("Subscribe");
  const [disabled, setDisabled] = useState(true);
  const [openCaptcha, setOpenCaptcha] = useState(false);
  const captchaDemo = useRef(null);

  const [helperText, setHelperText] = useState("");

  const classes = useStyles();

  useEffect(() => {
    if (status === "success") {
      setText("Subscribed!");
      setEmail("");
      ReactGA.event({
        category: "Newsletter",
        action: "join",
        label: "subscribed to newsletter",
      });
      handleSuccess();
    }
    if (status === "error") {
      setOpenCaptcha(false);
      setHelperText("This email is already subscribed");
    }
  }, [status]);

  useEffect(() => {
    if (!email.includes("@") || !email.includes(".")) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setHelperText("");
  }, [email]);

  const handleSubscribe = () => {
    if (!success) {
      setOpenCaptcha(true);
    }
  };

  const verifyCallback = () => {
    !disabled && subscribe({ EMAIL: email });
  };

  return (
    <>
      <TextField
        variant="outlined"
        classes={{ root: classes.signupInput }}
        placeholder="example@gmail.com"
        value={email}
        helperText={helperText}
        onFocus={() => setText("Subscribe")}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        color="primary"
        variant="contained"
        classes={{ root: classes.signupButton }}
        onClick={() => handleSubscribe()}
      >
        {text}
      </Button>

      {openCaptcha && (
        <ReCaptcha
          ref={captchaDemo}
          size="normal"
          render="explicit"
          data-theme="dark"
          sitekey={process.env.REACT_APP_CAPTCHA}
          verifyCallback={verifyCallback}
        />
      ) }
    </>
  );
};

const Newsletter = () => {
  const [success, setSuccess] = useState(false);

  const classes = useStyles();

  const url = process.env.REACT_APP_MAILCHIMP_URL;

  const handleSuccess = () => {
    setSuccess(true);
  };
  return (
    <Grid container direction="row" alignItems="center" justifyContent="space-around">
      <Box position="absolute" height={530} width={1} className={classes.knightBackground}>
        <img src="/images/newsletter-fight.png" alt="knight background" />
      </Box>
      <Grid
        item
        xs={12}
        md={5}
        xl={3}
        style={{ marginBottom: -5 }}
      >
        <img
          src="images/newsletter-horse.png"
          alt="horse knight"
        />
      </Grid>
      <Grid item xs={12} md={7} xl={5}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h4" paragraph>Are you ready to conquer new places?</Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Become the First to Know about NFT launch, NFT Drops, Giveaways, Auctions.
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Grid container direction="row">
              <MailchimpSubscribe
                url={url}
                render={({ subscribe, status }) => (
                  <FormFields
                    subscribe={subscribe}
                    status={status}
                    handleSuccess={handleSuccess}
                    success={success}
                  />
                )}
              />
            
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Newsletter;
