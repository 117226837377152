import { Paper, Box, Typography, makeStyles, Grid } from "@material-ui/core";
import SwiperCore, { Pagination, Mousewheel } from "swiper";
import Swiper from "react-id-swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Pagination, Mousewheel]);

const params = {
  centeredSlides: true,
  mousewheel: true,
  slidesPerView: 1,
  pagination: {
    el: ".swiper-pagination",
    clickable: false,
  },
};

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    background: "#00000080",
    width: "fit-content",
    margin: "auto",
  },
  box: {
    border: "1px solid #e2a23b",
    borderRadius: 5,
  },
  boxContainer: {
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridTemplateColumns: "50% 50%",
      gap: 40,
      justifyItems: "center",
    },
  },
  boxSwiper: {
    width: "100%",
    "& .swiper-container": {
      margin: "0px auto",
      position: "relative",
      overflow: "hidden",
      listStyle: "none",
      padding: 0,
      paddingBottom: 30,
      zIndex: 1,
      height: "100%",
      width: "100%",
      "& $boxHouse": {
        margin: "auto",
      },
      "& .swiper-pagination-bullets": {
        bottom: 0,
      },
      "& .swiper-pagination-bullet": {
        background: theme.palette.primary.main,
      },
    },
  },
}));

export const InfoBox = ({ icon: Icon, title, subtitle, children, ...boxArgs }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Box
        className={classes.box}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        textAlign="center"
        minHeight={375}
        maxWidth={275}
        padding={4}
        {...boxArgs}
      >
        <Box display="flex" alignItems="center" flex={1}>
          {Icon && (
            <Icon />
          )}
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-around" flex={1}>
          <Box display="flex" flex={1}>
            {title && (
              <Typography
                variant="h5"
                color="primary"
                paragraph
              >
                {title}
              </Typography>
            )}
          </Box>
          <Box display="flex" flex={1}>
            {subtitle && (
              <Typography
                variant="body2"
                color="secondary"
              >
                {subtitle}
              </Typography>
            )}
          </Box>
        </Box>
        {children}
      </Box>
    </Paper>
  );
};

export const TabletFeatureBox = ({ featureData }) => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="space-between" className={classes.boxContainer}>
      {
        featureData.map((item, i) => (
          <Grid key={i} item>
            <InfoBox
              icon={item.icon}
              title={item.title}
              subtitle={item.subtitle}
            />
          </Grid>
        ))
      }
    </Grid>
  );
};

export const MobileFeatureBox = ({ featureData }) => {
  const classes = useStyles();
  return (
    <Box className={classes.boxSwiper}>
      <Swiper {...params}>
        {featureData.map((item, i) => (
          <Grid key={i} item>
            <InfoBox
              icon={item.icon}
              title={item.title}
              subtitle={item.subtitle}
            />
          </Grid>
        ))}
      </Swiper>
    </Box>
  );
};
