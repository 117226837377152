import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import SwiperCore, { Navigation, Mousewheel } from "swiper";
import Swiper from "react-id-swiper";
import "swiper/swiper-bundle.css";
import CheckedIcon from "assets/Checkedicon";

SwiperCore.use([Navigation, Mousewheel]);

const useStyles = makeStyles((theme) => ({
  timeLineContainer: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    height: "100%",
    minHeight: 650,
    width: "100%",
    "&::after": {
      content: "''",
      position: "absolute",
      background: "#e2a23b",
      width: "100%",
      height: 8,
      top: "calc(50% - 4px)",
      right: 0,
      opacity: 0.2,
    },
    "& .swiper-container": {
      margin: "0px auto",
      position: "relative",
      overflow: "hidden",
      listStyle: "none",
      padding: 0,
      zIndex: 1,
      height: "100%",
      width: "100%",
    },
    "& .swiper-button-next, & .swiper-button-prev": {
      color: theme.palette.primary.main,
      border: "solid 1px",
      borderRadius: "100%",
      width: 25,
      height: 25,
      transform: "translateY(-50%)",
      marginTop: 0,
      "&:after": {
        fontSize: 13,
        fontWeight: 600,
      },
    },
    "& .swiper-button-next": {
      right: 0,
      paddingLeft: 2,
    },
    "& .swiper-button-prev": {
      left: 0,
      paddingRight: 2,
    },
  },
  swiperItem: {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    "&:nth-of-type(odd)": {
      justifyContent: "flex-start",
      "& $timeLineItem": {
        top: "50%",
        "& $timeLineDot": {
          top: -31,
          "& h4": {
            top: -80,
          },
        },
      },
    },
    "&:nth-of-type(even)": {
      justifyContent: "flex-end",
      "& $timeLineItem": {
        bottom: "50%",
        "& $timeLineDot": {
          bottom: -31,
          "& h4": {
            bottom: -80,
          },
        },
      },
    },
  },
  timeLineItem: {
    position: "relative",
    height: "fit-content",
    width: 250,
    margin: "22px 50px 22px 85px",
    padding: theme.spacing(2),
    borderRadius: 5,
    border: "1px solid #e2a23b",
    background: "#00000080",
    "&::before": {
      content: "''",
      height: "100%",
      width: 1,
      background: `${theme.palette.primary.main}80`,
      left: -20,
      position: "absolute",
      top: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: 230,
      margin: "22px 50px 22px 70px",
      padding: 14,
    },
  },
  timeLineDot: {
    position: "absolute",
    background: "#919094",
    borderRadius: "50%",
    left: -27,
    height: 16,
    width: 16,
    zIndex: 5,
    "& h4": {
      position: "absolute",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      textAlign: "center",
      color: "#e2a23b",
      margin: 16,
      width: 150,
      left: "calc(-100% - 75px + 8px)",
    },
  },
  timeLineContentTitle: {
    position: "relative",
    width: "fit-content",
    margin: 0,
  },
  timeLineContentSubtitle: {
    paddingTop: theme.spacing(1),
  },
  checkedIcon: {
    marginRight: theme.spacing(1),
  },
  itemContainer: {
    paddingBottom: theme.spacing(1),
  },
}));

const TimeLine = ({ data }) => {
  const classes = useStyles();

  const params = {
    slidesPerView: "auto",
    mousewheel: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };

  if (!data && typeof data !== "object" && !data.length) return;
  return (
    <Box className={classes.timeLineContainer}>
      <Swiper {...params}>
        {data.map((item, index) => (
          <div key={index} className={classes.swiperItem}>
            <Box className={classes.timeLineItem}>
              <Box className={classes.timeLineDot}>
                <Typography
                  variant="h4"
                  color="primary"
                >
                  {item.dot}
                </Typography>
              </Box>
              <Box className={classes.timeLineContent}>
                <Box className={classes.timeLineContentTitle}>
                  <Typography
                    variant="h5"
                    color="primary"
                  >
                    {item.title}
                  </Typography>
                </Box>
                <Box className={classes.timeLineContentSubtitle}>
                  {item.subtitle.map(({ text, checked }, index) => (
                    <Grid container flexDirection="row" className={classes.itemContainer}>
                      {checked && <CheckedIcon className={classes.checkedIcon} />}
                      <Typography
                        key={index}
                        variant="body2"
                        color="secondary"
                      >
                        {text.trim()}
                      </Typography>
                    </Grid>
                  ))}
                </Box>
              </Box>
            </Box>
          </div>
        ))}
      </Swiper>
    </Box>
  );
};

export default TimeLine;
