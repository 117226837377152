const UniswapIcon = (props) => (
  <svg
    width={53}
    height={60}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: `scale(${window.outerWidth < 768 ? 0.7 : 1})` }}
    {...props}
  >
    <path
      d="M20.413 13.655c-.646-.1-.673-.112-.37-.159.584-.09 1.96.033 2.91.259 2.214.527 4.229 1.878 6.38 4.276l.571.638.818-.132c3.444-.554 6.947-.114 9.878 1.242.806.373 2.077 1.116 2.236 1.307.05.06.144.452.206.87.218 1.445.11 2.552-.332 3.38-.24.45-.254.592-.092.978.128.307.488.535.844.534.728 0 1.512-1.18 1.875-2.82l.144-.652.286.324c1.567 1.779 2.799 4.205 3.01 5.932l.055.45-.263-.41c-.454-.704-.91-1.183-1.493-1.57-1.051-.697-2.164-.934-5.109-1.09-2.66-.14-4.165-.367-5.658-.855-2.54-.83-3.82-1.934-6.837-5.898-1.34-1.761-2.168-2.735-2.992-3.52-1.872-1.783-3.712-2.718-6.067-3.084Z"
      fill="#C2C2C2"
      fillOpacity={0.8}
    />
    <path
      d="M43.436 17.587c.067-1.18.227-1.958.548-2.669.127-.281.246-.511.264-.511.019 0-.036.207-.122.461-.234.69-.272 1.633-.111 2.731.204 1.393.32 1.594 1.79 3.098.69.706 1.49 1.596 1.782 1.978l.528.695-.528-.497c-.647-.607-2.133-1.79-2.462-1.96-.22-.114-.252-.112-.388.023-.126.125-.152.313-.17 1.199-.026 1.381-.214 2.268-.668 3.154-.245.48-.284.377-.062-.164.166-.404.183-.582.181-1.919-.002-2.686-.32-3.332-2.188-4.438a20.516 20.516 0 0 0-1.732-.899c-.48-.213-.86-.4-.847-.414.053-.052 1.874.48 2.607.762 1.09.42 1.27.475 1.403.424.089-.034.132-.293.175-1.054ZM21.67 22.183c-1.313-1.81-2.125-4.584-1.95-6.658l.055-.642.299.054c.56.103 1.528.464 1.98.739 1.244.756 1.782 1.751 2.33 4.307.16.749.37 1.596.467 1.883.156.462.744 1.54 1.223 2.24.345.505.116.744-.646.675-1.165-.105-2.742-1.195-3.759-2.598ZM41.844 35.65c-6.133-2.472-8.293-4.62-8.293-8.241 0-.533.018-.97.04-.97.023 0 .26.176.528.391 1.243 1 2.636 1.426 6.492 1.99 2.268.331 3.545.599 4.723.99 3.743 1.243 6.06 3.767 6.611 7.204.16.998.067 2.87-.194 3.858-.205.78-.832 2.185-.998 2.239-.046.015-.091-.162-.103-.403-.063-1.29-.714-2.547-1.808-3.488-1.243-1.07-2.914-1.922-6.998-3.57ZM37.538 36.678a10.923 10.923 0 0 0-.296-1.3l-.156-.466.29.326c.403.453.72 1.031.99 1.802.206.588.229.763.227 1.719-.001.938-.027 1.135-.217 1.664-.3.835-.67 1.427-1.294 2.063-1.12 1.142-2.561 1.774-4.64 2.036-.36.046-1.414.123-2.34.17-2.333.122-3.868.373-5.248.858-.198.07-.375.113-.393.095-.056-.056.883-.617 1.66-.991 1.093-.528 2.182-.816 4.623-1.223 1.205-.201 2.45-.445 2.766-.542 2.985-.917 4.52-3.283 4.029-6.21Z"
      fill="#C2C2C2"
      fillOpacity={0.8}
    />
    <path
      d="M40.35 41.68c-.815-1.755-1.002-3.449-.556-5.029.048-.169.125-.307.171-.307.046 0 .239.104.427.232.376.253 1.129.68 3.135 1.775 2.504 1.367 3.931 2.426 4.902 3.635.85 1.06 1.377 2.266 1.63 3.737.143.833.059 2.838-.154 3.677-.674 2.646-2.238 4.724-4.47 5.937-.327.178-.62.323-.652.324-.032 0 .088-.303.265-.674.75-1.572.836-3.1.269-4.802-.348-1.042-1.056-2.313-2.487-4.462-1.663-2.498-2.071-3.163-2.48-4.044ZM17.314 51.145C19.59 49.22 22.42 47.853 25 47.433c1.112-.18 2.963-.109 3.993.155 1.65.423 3.126 1.37 3.894 2.499.75 1.103 1.072 2.064 1.407 4.203.132.843.276 1.69.32 1.883.251 1.108.74 1.994 1.348 2.439.963.706 2.623.75 4.256.113.277-.109.518-.184.535-.167.059.06-.763.61-1.343.9-.78.391-1.401.542-2.226.542-1.495 0-2.737-.762-3.773-2.316-.204-.305-.662-1.221-1.018-2.034-1.094-2.5-1.634-3.26-2.904-4.093-1.105-.725-2.53-.855-3.603-.328-1.408.691-1.801 2.494-.793 3.637.401.454 1.15.845 1.76.921a1.88 1.88 0 0 0 2.127-1.885c0-.751-.288-1.18-1.014-1.508-.99-.447-2.055.076-2.05 1.008.002.398.175.648.572.828.256.116.262.125.053.081-.909-.188-1.122-1.286-.39-2.015.877-.875 2.692-.489 3.315.705.262.502.292 1.501.064 2.104-.51 1.35-2 2.06-3.512 1.674-1.028-.263-1.447-.548-2.688-1.828-2.155-2.223-2.992-2.654-6.1-3.14l-.595-.093.678-.573Z"
      fill="#C2C2C2"
      fillOpacity={0.8}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.06 1.33c7.198 8.75 18.296 22.374 18.847 23.137.455.63.284 1.196-.496 1.64-.433.246-1.324.496-1.77.496-.505 0-1.073-.243-1.487-.637-.293-.277-1.473-2.043-4.2-6.28-2.085-3.241-3.83-5.93-3.878-5.975-.11-.104-.108-.1 3.666 6.663 2.37 4.248 3.17 5.75 3.17 5.95 0 .408-.111.623-.614 1.185-.839.937-1.214 1.99-1.485 4.17-.303 2.442-1.156 4.168-3.52 7.12-1.384 1.73-1.61 2.046-1.96 2.743-.44.877-.56 1.369-.61 2.477-.052 1.171.05 1.928.407 3.048.313.98.639 1.628 1.474 2.923.72 1.118 1.135 1.948 1.135 2.273 0 .259.05.259 1.166.007 2.671-.605 4.84-1.668 6.06-2.97.756-.806.933-1.251.939-2.356.004-.722-.022-.873-.217-1.29-.317-.676-.895-1.238-2.169-2.11-1.669-1.143-2.381-2.063-2.578-3.328-.162-1.038.026-1.77.949-3.708.955-2.006 1.192-2.861 1.352-4.883.104-1.306.247-1.822.622-2.235.39-.431.742-.577 1.71-.71 1.577-.216 2.58-.624 3.406-1.386.716-.66 1.016-1.297 1.062-2.256l.035-.727-.4-.467C20.226 22.152.09 0 0 0c-.02 0 .457.599 1.06 1.33Zm9.492 44.014a1.286 1.286 0 0 0-.394-1.693c-.518-.345-1.322-.182-1.322.267 0 .137.076.237.246.325.287.148.308.315.082.655-.229.345-.21.648.052.853.424.332 1.023.15 1.337-.407ZM23.07 29.061c-.74.228-1.459 1.014-1.682 1.838-.136.503-.059 1.384.145 1.657.329.44.647.556 1.508.55 1.686-.012 3.151-.736 3.322-1.642.14-.742-.504-1.77-1.39-2.222-.458-.233-1.431-.325-1.902-.18Zm1.972 1.544c.26-.37.146-.77-.296-1.04-.842-.515-2.116-.09-2.116.708 0 .396.664.829 1.273.829.405 0 .96-.242 1.139-.497Z"
      fill="#C2C2C2"
      fillOpacity={0.8}
    />
  </svg>
);

export default UniswapIcon;
