import { Button, Typography, Hidden, Dialog, DialogTitle, IconButton, makeStyles, Grid, TextField } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import ReactGA from "react-ga";
import { ReCaptcha } from "react-recaptcha-google";

const url = process.env.REACT_APP_MAILCHIMP_URL;

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiPaper-root": {
      overflowY: "visible",
      background: "rgba(0,0,0,0.9)",
      "& .icon": {
        marginLeft: 5,
        transform: "scale(0.7)",
        "& path": {
          fill: "#000",
        },
      },
      maxWidth: 800,
    },
  },
  closeDialog: {
    position: "absolute",
    right: 0,
    top: 0,
    margin: 0,
    "&:hover": {
      background: "transparent",
    },
  },
  imgDialog: {
    position: "absolute",
    bottom: 0,
    width: "320px",
    left: 20,
    [theme.breakpoints.down("md")]: {
      width: "250px",
    },
  },
  signupInput: {
    width: 290,
    marginBottom: 20,
    "& .MuiFormHelperText-root": {
      color: theme.palette.primary.main,
      ...theme.typography.subtitle2,
    },
  },
  subscribeButton: {
    width: 290,
    height: 50,
    marginBottom: 70,
    "& .MuiButton-root.Mui-disabled": {
      color: theme.palette.primary.main,
    },
  },
  subtitle: {
    color: theme.palette.values,
    marginTop: 10,
    marginBottom: 20,
    textAlign: "center",
  },
  title: {
    paddingBottom: 0,
    marginTop: 20,
    textAlign: "center",
  },
}));

const FormFields = ({ status, subscribe, onSuccess, success, handleDialog }) => {
  const [email, setEmail] = useState("");
  const [text, setText] = useState("Subscribe");
  const [helperText, setHelperText] = useState("");
  const [openCaptcha, setOpenCaptcha] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const classes = useStyles();
  const captchaDemo = useRef(null);

  useEffect(() => {
    if (status === "success") {
      setText("Close");
      setEmail("");
      ReactGA.event({
        category: "Newsletter",
        action: "join",
        label: "subscribed to newsletter",
      });
      onSuccess();
    }
    if (status === "error") {
      setOpenCaptcha(false);
      setHelperText("This email is already subscribed");
    }
  }, [status]);

  useEffect(() => {
    if (!email.includes("@") || !email.includes(".")) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setHelperText("");
  }, [email]);

  const handleSubscribe = () => {
    if (!success) {
      setOpenCaptcha(true);
    } else {
      handleDialog();
    }
  };

  const verifyCallback = () => {
    !disabled && subscribe({ EMAIL: email });
  };

  return (
    <Grid container justifyContent="center">
      {!openCaptcha || success
        ? (
          <>
            <Grid item>
              {!success && (
                <TextField
                  variant="outlined"
                  classes={{ root: classes.signupInput }}
                  placeholder="example@gmail.com"
                  value={email}
                  helperText={helperText}
                  onFocus={() => setText("Subscribe")}
                  onChange={(e) => setEmail(e.target.value)}
                />
              )}
            </Grid>
            <Grid item>
              <Button
                variant={success ? "outlinedPrimary" : "containedPrimary"}
                className={classes.subscribeButton}
                onClick={() => handleSubscribe()}
              >
                {text}
              </Button>
            </Grid>
          </>
        )
        : (
          <Grid item style={{ marginBottom: 20 }}>
            <ReCaptcha
              ref={captchaDemo}
              size="normal"
              render="explicit"
              data-theme="dark"
              sitekey={process.env.REACT_APP_CAPTCHA}
              verifyCallback={verifyCallback}
            />
          </Grid>
        )}
    </Grid>
  );
};

const WelcomeDialog = ({ handleDialog, openDialog }) => {
  const [success, setSuccess] = useState(false);
  const classes = useStyles();

  const handleSuccess = () => {
    setSuccess(true);
  };
  return (
    <Dialog
      open={openDialog}
      onClose={handleDialog}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
    >
      <Grid container justifyContent="center">
        <Hidden smDown>
          {!success && (
            <Grid item xs={4}>
              <img src="images/swordsman.png" alt="swordsman" className={classes.imgDialog} />
            </Grid>
          )}
        </Hidden>
        <Grid item xs={5}>
          <Grid container justifyContent="center">
            <Grid item>
              <DialogTitle id="alert-dialog-title" className={classes.title}>
                <Typography variant="h4" align="center">
                  {success ? "Thank you for subscribing " : "Hello Terranian!"}
                </Typography>
              </DialogTitle>
            </Grid>
            {!success && (
              <IconButton
                onClick={handleDialog}
                className={classes.closeDialog}
              >
                <CloseIcon
                  fontSize="large"
                  style={{
                    border: "solid 1px",
                    borderRadius: "100%",
                    padding: 5,
                  }}
                  color="primary"
                />
              </IconButton>
            )}
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={classes.subtitle}>
                {success ? "Stay tuned for updates!" : "Subscribe to our newsletter"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <MailchimpSubscribe
                url={url}
                render={({ subscribe, status }) => (
                  <FormFields
                    subscribe={subscribe}
                    status={status}
                    onSuccess={handleSuccess}
                    success={success}
                    handleDialog={handleDialog}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default WelcomeDialog;
