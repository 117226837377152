import { Box, Grid, Typography, Slide, Container, useMediaQuery, useTheme } from "@material-ui/core";
import DividerTop from "assets/svgr/dividers/DividerBottom";
import { TabletFeatureBox, MobileFeatureBox } from "components/FeatureBox";
import InView from "react-intersection-observer";
import { data } from "./utils";

const DecentralizedFinance = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid item xs={12}>
      <Container maxWidth="lg">
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" mt={11} mb={8}>
          <Typography variant="h4" paragraph>
            Decentralized Finance in Terrae
          </Typography>
          <DividerTop />
        </Box>
      </Container>
      <InView triggerOnce delay={500}>
        {({ inView, ref }) => (
          <Box ref={ref} marginBottom={8}>
            <Slide in={inView} direction="up" {...(inView ? { timeout: 500 } : {})}>
              <Container maxWidth="lg">
                {(() => (
                  <>
                    {
                      isSm ? <MobileFeatureBox featureData={data} />
                        : <TabletFeatureBox featureData={data} />
                    }
                  </>
                ))()}
              </Container>
            </Slide>
          </Box>
        )}
      </InView>
    </Grid>
  );
};

export default DecentralizedFinance;
