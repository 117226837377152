import { useEffect, useState } from "react";
import { ThemeSelectorContext } from "store/Theme";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { dark } from "theme";

const withTheme = (Component) => (props) => {
  const [t, setT] = useState("dark");
  const toggleTheme = () => {
    const newTheme = t === "light" ? "dark" : "dark";
    localStorage.setItem("theme", newTheme);
    setT(newTheme);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setT(savedTheme);
    }
  }, []);
  const theme = t === "light" ? dark : dark;
  
  return (
    <ThemeSelectorContext.Provider value={toggleTheme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </ThemeProvider>
    </ThemeSelectorContext.Provider>
  );
};

export default withTheme;
