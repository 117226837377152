const LogoMobile = (props) => (
  <svg
    width={53}
    height={99}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#prefix__filter0_d_195_25)">
      <path
        d="M20.81 31.187a227.83 227.83 0 00-5.398-.26c-1.606-.06-2.645-.09-3.104-.121-1.789-.046-3.21.29-4.235 1.036-1.04.747-1.682 1.616-1.942 2.622-.26.99-.183 1.997.245 3.018.428 1.006 1.208 1.768 2.34 2.287-.093.015-.215-.016-.353-.077-.29-.091-.733-.411-1.314-.975-1.315-1.174-2.156-2.622-2.508-4.36-.352-1.722-.168-3.368.566-4.908.734-1.555 2.049-2.82 3.96-3.81 1.91-.991 4.464-1.342 7.675-1.037 4.281.183 8.18.35 11.697.518 1.468.061 2.935.122 4.388.198 1.452.077 2.767.138 3.945.168 1.177.03 2.155.061 2.935.107.78.046 1.27.046 1.453.03 2.11.046 3.654-.335 4.602-1.158.948-.823 1.56-1.738 1.82-2.744.32-1.189.336-2.515.015-3.978.367.945.52 2.21.428 3.765-.092 1.57-.597 3.11-1.545 4.618-.947 1.51-2.415 2.82-4.433 3.918-2.019 1.097-4.756 1.661-8.242 1.676.046.046.123.046.214.03h-.963l-12.247-.563zm9.678.412c-1.07-.077-2.432-.122-4.113-.168a154.062 154.062 0 01-5.153-.229l9.265.396z"
        fill="#E2A23B"
      />
    </g>
    <g filter="url(#prefix__filter1_d_195_25)" fill="#E2A23B">
      <path d="M23.225 73.974l2.569 2.012V91l-2.57-4.207v-12.82zM28.362 77.632l-2.201-1.463V91l2.201-4.39v-8.978zM28.026 0h-4.954v1.646h4.954V0zM26.956 6.554H24.02v1.647h2.936V6.554zM26.956 4.466H24.02V5.93h2.936V4.466zM26.956 10.914H24.02v1.463h2.936v-1.463zM27.323 14.466h-3.67l.367-1.464h2.936l.367 1.463zM26.956 2.378H24.02V3.84h2.936V2.378zM26.956 8.826H24.02v1.28h2.936v-1.28zM23.622 15.929l-1.834-.915s-1.285 1.83-4.77 1.646v-.548l-2.019.914 2.018.915v-.549h6.605V15.93zM27.659 15.929l1.835-.915s1.284 1.83 4.77 1.646v-.548l2.018.914-2.018.915v-.549h-6.605V15.93z" />
      <path
        d="M25.549 17.758a1.19 1.19 0 001.193-1.189 1.19 1.19 0 00-1.193-1.189 1.19 1.19 0 00-1.193 1.189 1.19 1.19 0 001.193 1.189z"
        stroke="#E2A23B"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path d="M25.274 19.953v-1.28a1.17 1.17 0 01-1.01-.732H22.89v4.801c.81.03 1.605.077 2.385.107v-2.896zM28.21 22.986v-5.03h-1.377c-.168.396-.55.686-1.009.732v4.192c.474.015.963.045 1.422.06l.963.046z" />
    </g>
    <g filter="url(#prefix__filter2_d_195_25)" fill="#E2A23B">
      <path d="M28.576 75.147l-2.049-4.344a18.703 18.703 0 01-1.605-1.372 9.231 9.231 0 01-1.269-1.509l-.138.199a8.441 8.441 0 01-1.467 1.295c-.474.32-.933.564-1.33.747-.505.183-.964.335-1.377.472.872-.365 1.514-.823 1.957-1.371.429-.55.75-1.159.933-1.845a8.464 8.464 0 00.275-2.195v-8.003c-.504 0-.978-.09-1.437-.274a3.54 3.54 0 01-.55-.335c.367 0 .688-.107.963-.335.23-.183.459-.488.688-.93.23-.427.336-1.082.336-1.951V43.077c0-.458-.229-.686-.688-.686l-1.162.06c1.697-.731 3.165-1.524 4.419-2.393 1.254-.868 2.263-1.722 3.012-2.56.75-.839 1.27-1.631 1.544-2.363.276-.732.276-1.326 0-1.784.276.23.428.564.474 1.022.046.366-.03.884-.244 1.54-.2.655-.627 1.493-1.27 2.5v5.03l.887-.687c.184-.137.505-.411.964-.823a35.791 35.791 0 002.614-2.591c.367-.412.55-.732.55-.96l5.352 3.826c.826.503 1.33 1.143 1.514 1.92.183.778.152 1.6-.062 2.454a8.431 8.431 0 01-1.207 2.592 12.584 12.584 0 01-1.958 2.332c-.733.686-1.498 1.25-2.293 1.676-.795.427-1.544.656-2.232.656.917-.366 1.697-.823 2.34-1.372a5.4 5.4 0 001.299-1.814c.32-.701.29-1.555-.061-2.56a4.11 4.11 0 00-.964-1.327c-.413-.38-.795-.747-1.162-1.067l-4.357-2.515c-.275.229-.55.549-.826.96-.275.412-.413 1.098-.413 2.058v5.67c.688-.045 1.208.061 1.575.336.184.137.321.29.413.472-.413 0-.75.092-1.024.275-.23.183-.444.488-.658.93-.199.426-.306 1.082-.306 1.95v19.313z" />
      <path d="M28.026 58.64v15.456l.32.274c.291.183.567.274.811.274.03.03.107.061.214.061s.2-.015.276-.03c.076-.015.137-.03.214-.03h.06c.215-.077.383-.138.49-.214l12.736-7.408c.428-.244.78-.61 1.025-1.082.26-.473.397-.96.428-1.479a3.394 3.394 0 00-.275-1.54c-.215-.502-.566-.914-1.025-1.25-.688-.472-1.33-.746-1.957-.838a6.283 6.283 0 00-1.682-.03 6.319 6.319 0 00-1.62.533 3.368 3.368 0 011.865-.945 5.29 5.29 0 012.278.137c.78.214 1.529.61 2.278 1.19.734.579 1.346 1.326 1.82 2.27.214.397.382.9.52 1.51.122.61.137 1.295.03 2.027-.107.732-.382 1.509-.84 2.317-.46.808-1.178 1.6-2.203 2.347-.03.03-.06.061-.076.061-.015 0-.046.015-.077.061-.58.32-1.269.717-2.079 1.19-.81.472-1.666.944-2.553 1.432s-1.774.976-2.66 1.479c-.887.503-1.713.975-2.462 1.402-.184.076-.413.183-.703.32-.398.137-.796.091-1.193-.168-.107-.076-.382-.259-.81-.564-.429-.305-.948-.67-1.545-1.082-.596-.411-1.223-.869-1.896-1.356-.672-.488-1.3-.93-1.865-1.357-.581-.412-1.254-.884-2.003-1.433-.611-.427-.917-1.067-.917-1.89V58.609h-.107l.107-4.207V41.384c0-1.112-.123-2.118-.383-3.002-.26-.884-.596-1.631-1.024-2.241-.428-.61-.917-1.082-1.437-1.402-.52-.32-1.055-.488-1.606-.488a5.81 5.81 0 00-2.247.457c-.734.305-1.33.732-1.789 1.265-.459.534-.673 1.19-.658 1.951.016.762.444 1.586 1.27 2.485.183.183.672.655 1.483 1.433.81.777 1.681 1.63 2.63 2.56.58.534.947 1.083 1.116 1.647.168.564.152 1.128-.031 1.722-.184.595-.49 1.204-.917 1.845-.428.624-.964 1.31-1.575 2.027-.719.823-1.667 1.509-2.813 2.058-1.162.533-2.493.716-4.006.533 1.697-.107 2.996-.38 3.899-.838.902-.458 1.513-.96 1.819-1.51.306-.563.367-1.127.183-1.706a3.66 3.66 0 00-.917-1.51c-1.59-1.509-2.721-2.987-3.394-4.435-.673-1.433-.994-2.76-.979-3.979.015-1.204.306-2.27.872-3.185.566-.915 1.254-1.631 2.08-2.134.764-.534 1.742-.915 2.95-1.128 1.208-.214 2.446-.183 3.715.076 1.27.274 2.462.823 3.578 1.646 1.116.823 1.988 2.012 2.6 3.567.213.534.366 1.143.458 1.814.092.67.153 1.31.184 1.92l.015 15.807zm0-14.1v9.892z" />
    </g>
    <defs>
      <filter
        id="prefix__filter0_d_195_25"
        x={0.368}
        y={17.743}
        width={51.682}
        height={30.03}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_195_25"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_195_25"
          result="shape"
        />
      </filter>
      <filter
        id="prefix__filter1_d_195_25"
        x={10.999}
        y={0}
        width={29.283}
        height={99}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_195_25"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_195_25"
          result="shape"
        />
      </filter>
      <filter
        id="prefix__filter2_d_195_25"
        x={6.963}
        y={33.351}
        width={43.941}
        height={52.859}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_195_25"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_195_25"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default LogoMobile;
