const RatherIcon = (props) => (
  <svg
    width={51}
    height={60}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: `scale(${window.outerWidth < 768 ? 0.5 : 1})` }}
    {...props}
  >
    <path
      d="M50.72 60L0 2.01v19.487L32.644 60h18.077zM20.088 60L0 37.107V60h20.088zM34.207 25.864a7.95 7.95 0 002.028-5.31 7.949 7.949 0 00-2.028-5.311 6.66 6.66 0 00-2.21-1.612 6.586 6.586 0 00-2.662-.581h-6.263L11.63.02h17.694a17.544 17.544 0 017.49 1.285 18.724 18.724 0 016.452 4.38 20.63 20.63 0 014.323 6.78 22.195 22.195 0 010 16.13 20.63 20.63 0 01-4.323 6.78l-.36.376-8.699-9.887z"
      fill="#C2C2C2"
      fillOpacity={0.8}
    />
  </svg>
);

export default RatherIcon;
