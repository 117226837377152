export default Object.freeze({
  DISCORD: "https://discord.com/invite/terraedefi",
  TWITTER: "https://twitter.com/TerraeDefi",
  MEDIUM: "https://medium.com/terraedefi",
  DOCUMENTATION: "https://docs.terrae.finance",
  RATHER: "https://ratherlabs.com",
  WHITEPAPER: "whitepaper.pdf",
  ONEPAGER: "onepager.pdf",
  UNISWAP: "https://docs.uniswap.org/protocol/V2/introduction",
  AVALANCHE: "https://www.avax.network",
  POLYGON: "https://polygon.technology",
  GAMEPLAY_DOCS: "https://docs.terrae.finance/terrae/game/the-gameplay",
  BATTLE_DOCS: "https://docs.terrae.finance/terrae/game/the-gameplay/battles",
  REDDIT: "https://www.reddit.com/r/TerraeDeFi/",
  YOUTUBE: "https://www.youtube.com/channel/UCn3zXIe94vxz3u5YtxzTjxg",
  TELEGRAM: "https://t.me/terraecommunity",
  LINKEDIN: "https://www.linkedin.com/company/ratherlabs/mycompany/",
  HOUSES: "https://docs.terrae.finance/terrae/lore/terrae-houses",
  WEBAPP: "https://terrae-webapp-inky.vercel.app/login",
});
