import { Box, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import Landing from "views/landing/Landing";
import Airdrop from "views/Airdrop";
import { useEffect } from "react";
import { loadReCaptcha } from "react-recaptcha-google";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const App = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles({ isMobile });

  useEffect(() => {
    ReactGA.initialize("UA-219762873-1");
    loadReCaptcha();
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [window.location.pathname]);
  
  return (
    <Box className={classes.container}>
      <Router>
        <Switch>
          <Route exact strict path="/" component={Landing} />
          <Route exact strict path="/airdrop" component={Airdrop} />
        </Switch>
      </Router>
    </Box>
  );
};

export default App;
