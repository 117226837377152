import React from "react";
import ReactDOM from "react-dom";
import withTheme from "hocs/withTheme";
import withDialogs from "hocs/withDialogs";
import App from "./App";
import "./index.css";

// order matters!
const hocs = [
  withDialogs,
  withTheme,
];

const AppContainer = hocs.reduce((app, wrapper) => wrapper(app), App);

ReactDOM.render(
  <React.StrictMode>
    <AppContainer />
  </React.StrictMode>,
  document.getElementById("root"),
);
