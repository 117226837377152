const LinkedinIcon = (props) => (
  <svg
    width={28}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.6641 9C21.7858 9 23.8206 9.82665 25.3209 11.2981C26.8212 12.7695 27.6641 14.7652 27.6641 16.8462V26H22.3307V16.8462C22.3307 16.1525 22.0498 15.4873 21.5497 14.9968C21.0496 14.5063 20.3713 14.2308 19.6641 14.2308C18.9568 14.2308 18.2785 14.5063 17.7784 14.9968C17.2783 15.4873 16.9974 16.1525 16.9974 16.8462V26H11.6641V16.8462C11.6641 14.7652 12.5069 12.7695 14.0072 11.2981C15.5075 9.82665 17.5423 9 19.6641 9Z"
      fill="#E2A23B"
    />
    <path
      d="M5.66406 10H0.664062V26H5.66406V10Z"
      fill="#E2A23B"
    />
    <path
      d="M3.16406 5C4.54477 5 5.66406 3.88071 5.66406 2.5C5.66406 1.11929 4.54477 0 3.16406 0C1.78335 0 0.664062 1.11929 0.664062 2.5C0.664062 3.88071 1.78335 5 3.16406 5Z"
      fill="#E2A23B"
    />
  </svg>
);

export default LinkedinIcon;
