import { Box, Container } from "@material-ui/core";
import Topbar from "layout/Topbar";
import Footer from "layout/Footer";

const MainLayout = ({ children }) => (
  <Box display="flex" flexDirection="column" height={1} width={1}>
    <Topbar />
    <Container
      display="flex"
      disableGutters
      maxWidth={false}
    >
      <Box marginTop="125px">
        {children}
      </Box>
    </Container>
    <Footer />
  </Box>
);

export default MainLayout;
