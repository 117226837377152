import { Container, Box, Grid, Typography, Link, Slide, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { InView } from "react-intersection-observer";
import LogoLarge from "assets/svgr/logotype/LogoLarge";
import links from "links";
import TwitterIcon from "assets/svgr/social-media/TwitterIcon";
import DiscordIcon from "assets/svgr/social-media/DiscordIcon";
import MediumIcon from "assets/svgr/social-media/MediumIcon";
import RedditIcon from "assets/svgr/social-media/RedditIcon";
import YouTubeIcon from "assets/svgr/social-media/YouTubeIcon";
import TelegramIcon from "assets/svgr/social-media/TelegramIcon";

const useStyles = makeStyles(() => ({
  footer: {
    backgroundColor: "#15181f",
  },
  copyright: {
    backgroundColor: "#121314",
  },
  socialMediaIcon: {
    cursor: "pointer",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <InView triggerOnce delay={500}>
        {({ inView, ref }) => (
          <Box ref={ref} className={classes.footer}>
            <Container maxWidth="lg">
              <Box marginY={8}>
                <Grid container spacing={2}>
                  <Slide in={inView} direction="up" {...(inView ? { timeout: 500 } : {})}>
                    <Grid item xs={10} md={4} lg={3} xl={2}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <LogoLarge />
                        </Grid>
                        <Grid item>
                          <Typography variant="body1" color="secondary">
                            Decentralized finance and gaming in the Middle Earth.
                            Swap and Farm $DENI token
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Slide>
                  <Grid item md={8} lg={2} xl={4} />
                  <Slide in={inView} direction="up" {...(inView ? { timeout: 1000 } : {})}>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <Typography variant="h4" paragraph>Protocol</Typography>
                      <Grid container direction="column">
                        <Grid item>
                          <Link
                            href={links.UNISWAP}
                            component="button"
                            variant="body1"
                            color="secondary"
                            onClick={() => window.open(links.UNISWAP, "_blank")}
                          >
                            Uniswap v2
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link
                            href={links.POLYGON}
                            component="button"
                            variant="body1"
                            color="secondary"
                            onClick={() => window.open(links.POLYGON, "_blank")}
                          >
                            Polygon
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Slide>
                  <Slide in={inView} direction="up" {...(inView ? { timeout: 1500 } : {})}>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <Typography variant="h4" paragraph>About</Typography>
                      <Grid container direction="column">
                        <Grid item>
                          <Link
                            href={links.RATHER}
                            component="button"
                            variant="body1"
                            color="secondary"
                            onClick={() => window.open(links.RATHER, "_blank")}
                          >
                            Rather Labs
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link
                            href="/"
                            component="button"
                            variant="body1"
                            color="secondary"
                            onClick={() => window.open("mailto:contact@ratherlabs.com")}
                          >
                            contact@ratherlabs.com
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Slide>
                  <Slide in={inView} direction="up" {...(inView ? { timeout: 2000 } : {})}>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                      <Typography variant="h4" paragraph>Documentation</Typography>
                      <Grid container direction="column">
                        <Grid item>
                          <Link
                            href={links.ONEPAGER}
                            component="button"
                            variant="body1"
                            color="secondary"
                            onClick={() => window.open(links.ONEPAGER, "_blank")}
                          >
                            One Pager
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link
                            href={links.WHITEPAPER}
                            component="button"
                            variant="body1"
                            color="secondary"
                            onClick={() => window.open(links.WHITEPAPER, "_blank")}
                          >
                            Whitepaper
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link
                            href={links.DOCUMENTATION}
                            component="button"
                            variant="body1"
                            color="secondary"
                            onClick={() => window.open(links.DOCUMENTATION, "_blank")}
                          >
                            Gitbook
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Slide>
                  <Slide in={inView} direction="up" {...(inView ? { timeout: 2000 } : {})}>
                    <Box display="flex" alignItems="center" mt={5} gridGap={12} justifyContent={isXs ? "center" : "flex-start"} width={1}>
                      <RedditIcon
                        className={classes.socialMediaIcon}
                        onClick={() => window.open(links.REDDIT, "_blank")}
                      />
                      <TwitterIcon
                        className={classes.socialMediaIcon}
                        onClick={() => window.open(links.TWITTER, "_blank")}
                      />
                      <DiscordIcon
                        className={classes.socialMediaIcon}
                        onClick={() => window.open(links.DISCORD, "_blank")}
                      />
                      <YouTubeIcon
                        className={classes.socialMediaIcon}
                        onClick={() => window.open(links.YOUTUBE, "_blank")}
                      />
                      <TelegramIcon
                        className={classes.socialMediaIcon}
                        onClick={() => window.open(links.TELEGRAM, "_blank")}
                      />
                      <MediumIcon
                        className={classes.socialMediaIcon}
                        onClick={() => window.open(links.MEDIUM, "_blank")}
                      />
                    </Box>
                  </Slide>
                </Grid>
              </Box>
            </Container>
          </Box>
        )}
      </InView>
      <Box className={classes.copyright} paddingY={5}>
        <Container maxWidth="lg">
          <Typography color="secondary" variant="body2">Copyright © 2022 Terrae. All rights reserved.</Typography>
        </Container>
      </Box>
    </>
  );
};
export default Footer;
