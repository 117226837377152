const YouTubeIcon = (props) => (
  <svg
    width={34}
    height={33}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.8532 4.35498C12.2954 4.35498 6.37982 5.49695 6.37982 5.49695L6.36493 5.51396C4.28911 5.84595 2.69873 7.62969 2.69873 9.799V16.3318V16.3339V22.8646V22.8668C2.70076 23.9028 3.07205 24.9042 3.74594 25.6911C4.41983 26.478 5.35218 26.9989 6.37557 27.1603L6.37982 27.1667C6.37982 27.1667 12.2954 28.3108 16.8532 28.3108C21.4109 28.3108 27.3265 27.1667 27.3265 27.1667L27.3286 27.1646C28.3531 27.0035 29.2866 26.4822 29.961 25.6943C30.6354 24.9065 31.0065 23.9038 31.0076 22.8668V22.8646V16.3339V16.3318V9.799C31.0061 8.7626 30.635 7.76071 29.9611 6.97334C29.2872 6.18598 28.3545 5.66476 27.3308 5.50333L27.3265 5.49695C27.3265 5.49695 21.4109 4.35498 16.8532 4.35498ZM13.5868 11.3216L22.2972 16.3318L13.5868 21.342V11.3216Z"
      fill="#E2A23B"
    />
  </svg>
);

export default YouTubeIcon;
