import { Box, Typography, makeStyles, useTheme, useMediaQuery, Fade } from "@material-ui/core";
import { InView } from "react-intersection-observer";
import LinkedinIcon from "assets/svgr/social-media/LinkedinIcon";
import TwitterIcon from "assets/svgr/social-media/TwitterIcon";
import React from "react";
import HorizontalArragement from "./HorizontalArragement";

const datateam = [
  {
    name: "Federico Caccia",
    photo: "fede.jpg",
    position: "Project Lead",
    redes: [
      {
        icon: <TwitterIcon />,
        url: "https://twitter.com/fedeecaccia",
      },
      {
        icon: <LinkedinIcon />,
        url: "https://linkedin.com/in/fedecaccia",
      },
    ],
  },
  {
    name: "Franco Scucchiero",
    photo: "franco.jpg",
    position: "Tech Lead",
    redes: [
      {
        icon: <LinkedinIcon />,
        url: "https://linkedin.com/in/scucchiero",
      },
    ],
  },
  {
    name: "Ramiro Carracedo",
    photo: "ramiro.jpg",
    position: "Blockchain Developer",
    redes: [
      {
        icon: <LinkedinIcon />,
        url: "https://www.linkedin.com/in/carracedoramiro/",
      },
    ],
  },
  {
    name: "Fran Niño Rabadán",
    photo: "fran.jpg",
    position: "Illustrator",
    redes: [
      {
        icon: <LinkedinIcon />,
        url: "https://www.linkedin.com/in/frannino/",
      },
    ],
  },
  {
    name: "Solkiomary Pineda",
    photo: "sol.jpg",
    position: "UX/UI Designer",
    redes: [
      {
        icon: <TwitterIcon />,
        url: "https://twitter.com/solkiomary",
      },
      {
        icon: <LinkedinIcon />,
        url: "https://www.linkedin.com/in/solkiomary",
      },
    ],
  },
  {
    name: "Alan zukerman",
    photo: "alan.jpeg",
    position: "Frontend Developer",
    redes: [
      {
        icon: <TwitterIcon />,
        url: "https://twitter.com/ZukermanAlan",
      },
    ],
  },
  {
    name: "Roman Vitolo",
    photo: "roman.jpg",
    position: "Community Growth & Game Design",
    redes: [
      {
        icon: <LinkedinIcon />,
        url: "https://www.linkedin.com/in/roman-alberto-vitolo-787b5b186/",
      },
    ],
  },
  {
    name: "Priscila Malnero",
    photo: "priscila.png",
    position: "Community manager",
    redes: [
      {
        icon: <LinkedinIcon />,
        url: "https://www.linkedin.com/in/priscila-malnero-433291182/",
      },
    ],
  },
  {
    name: "Fede Reyes",
    photo: "fedeReyes.png",
    position: "Community Manager",
    redes: [
      {
        icon: <TwitterIcon />,
        url: "https://twitter.com/_FedeRy",
      },
      {
        icon: <LinkedinIcon />,
        url: "www.linkedin.com/in/Federicoreyesarce",
      },
    ],
  },
  {
    name: "Eduardo Yuschuk",
    photo: "edu.png",
    position: "Blockchain Developer",
    redes: [
      {
        icon: <LinkedinIcon />,
        url: "https://www.linkedin.com/in/eduardo-yuschuk",
      },
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  info: {
    position: "relative",
    zIndex: 0,
    background: "#0008",
    padding: "55px 15px 20px",
    marginTop: "-30px",
    minHeight: 190,
  },
  photo: {
    padding: 15,
    border: `solid 1px ${theme.palette.primary.main}`,
    width: "fit-content",
    margin: "auto",
    position: "relative",
    zIndex: 1,
    "& img": {
      maxWidth: 160,
      [theme.breakpoints.down("xs")]: {
        maxWidth: 120,
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: 10,
    },
  },
}));

const Team = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <InView triggerOnce delay={500}>
      {({ inView, ref }) => (
        <Box ref={ref} width={1} display="flex" flexWrap="wrap" justifyContent="space-evenly" alignItems={isSm ? "flex-start" : "center"}>
          <Fade in={inView}>
            <Box width={isSm ? "100%" : "25%"} minWidth="fit-content" my={isSm ? 5 : 0}>
              <picture>
                <source media="(max-width:959px)" srcSet="images/flag-horizontal.png" />
                <img src="images/flag-vertical.png" alt="flag" />
              </picture>
            </Box>
          </Fade>
          {datateam.map((item, i) => (
            <Fade in={inView} style={{ transitionDelay: inView ? `${i * 100}ms` : "0ms" }} key={i}>
              <Box width="25%" minWidth="fit-content" my={isSm ? 2 : 5}>
                <Box width={isXs ? 165 : 288} m="auto">
                  <Box className={classes.photo}>
                    <img src={`photos/${item.photo}`} alt="" />
                  </Box>
                  <Box className={classes.info}>
                    <Typography variant="h5" gutterBottom style={{ fontWeight: 700 }}>
                      {item.name}
                    </Typography>
                    <Typography variant="body2" color="secondary">
                      {item.position}
                    </Typography>
                    <HorizontalArragement width={1} justifyContent="center" height={33} mt={2}>
                      {item.redes?.map((red, j) => (
                        <Box onClick={() => window.open(red.url, "_blank")} key={j} mx={1} style={{ cursor: "pointer" }}>
                          {red.icon}
                        </Box>
                      ))}
                    </HorizontalArragement>
                  </Box>
                </Box>
              </Box>
            </Fade>
          ))}
        </Box>
      )}
    </InView>
  );
};

export default Team;
