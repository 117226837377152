const TwitterIcon = (props) => (
  <svg
    width={33}
    height={33}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30.66 7.553a11.594 11.594 0 01-3.336.915 5.817 5.817 0 002.553-3.213 11.644 11.644 0 01-3.688 1.41 5.808 5.808 0 00-9.896 5.296A16.488 16.488 0 014.32 5.89a5.794 5.794 0 00-.785 2.922 5.803 5.803 0 002.583 4.833 5.797 5.797 0 01-2.631-.726v.073a5.809 5.809 0 004.659 5.694 5.812 5.812 0 01-2.623.1 5.815 5.815 0 005.426 4.035 11.65 11.65 0 01-7.214 2.486c-.468 0-.93-.027-1.386-.081a16.442 16.442 0 008.903 2.608c10.684 0 16.524-8.85 16.524-16.524 0-.252-.005-.502-.016-.752a11.82 11.82 0 002.9-3.006z"
      fill="#E2A23B"
    />
  </svg>
);

export default TwitterIcon;
