import BankIcon from "assets/BankIcon";
import DenarisIcon from "assets/DenarisIcon";
import FarmIcon from "assets/FarmIcon";
import PolygonIconDraw from "assets/PolygonIconDraw";

export const data = [
  { icon: PolygonIconDraw, title: "Polygon", subtitle: "Running on a fast, cheap and green 3rd generation blockchain." },
  { icon: DenarisIcon, title: "Denaris", subtitle: "Denaris ($DENI) is the universal currency in Terrae." },
  { icon: FarmIcon, title: "Swap & Farm", subtitle: "Swap $DENI and resources in DEX, get LPs and earn yield farming." },
  { icon: BankIcon, title: "Terrae Houses", subtitle: "Stake $DENI on your House to earn passive yield from weekly competitions." },
];
