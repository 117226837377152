const FarmIcon = (props) => (
  <svg
    width={104}
    height={100}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M72.861 6.585L67.213 0l-3.692 3.168 6.13 7.15a4.836 4.836 0 013.21-3.733zM78.881 9.41l5.841-5.842L81.278.124 75.01 6.392a4.797 4.797 0 013.871 3.017zM69.61 11.558l-6.172 6.654 3.568 3.306 5.51-5.951c-1.597-.661-2.74-2.19-2.906-4.009zM79.198 11.365a4.785 4.785 0 01-2.727 4.105l6.392 5.924 3.306-3.568-6.97-6.46zM16.531 70.382v28.93H6.888V80.024l9.643-9.643zM16.531 60.738v5.51L5.511 77.27H0l16.531-16.532zM86.789 70.382v28.93h9.643V80.024l-9.643-9.643zM86.789 60.738v5.51l11.02 11.022h5.511L86.789 60.738z"
      fill="#E2A23B"
    />
    <path
      d="M12.398 60.738H4.133L50.97 16.655 97.81 60.738h-7.577L50.97 23.543 12.398 60.738z"
      fill="#E2A23B"
    />
    <path
      d="M50.971 29.054l-30.307 28.93.689 42.016h19.286V72.448h8.266V100h4.133V72.448h9.643V100H80.59V57.294L50.97 29.054zm-1.377 26.174H39.95v-4.133h9.644v4.133zm0-6.888H39.95v-4.133h9.644v4.133zm12.398 6.888h-9.643v-4.133h9.643v4.133zm0-6.888h-9.643v-4.133h9.643v4.133zM81.278 40.074V26.298l-6.888-8.265-5.51 6.888v2.755l12.398 12.398zM74.39 13.9a2.755 2.755 0 100-5.51 2.755 2.755 0 000 5.51z"
      fill="#E2A23B"
    />
  </svg>
);

export default FarmIcon;
