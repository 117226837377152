export const timelineData = [
  {
    dot: "Stage 0",
    title: "February 2022",
    subtitle: [
      { text: "Concept Design.", checked: true },
    ],
  },
  {
    dot: "Stage 1",
    title: "April 2022",
    subtitle: [
      { text: "Landing page desing.", checked: true },
      { text: "Tokenomics model.", checked: true },
      { text: "Gitbook (v1).", checked: true },
      { text: "Twitter and Discord communities.", checked: true },
      { text: "Denaris Givaway.", checked: true },
      { text: "DENI launch.", checked: true },
    ],
  },
  {
    dot: "Stage 2",
    title: "May 2022",
    subtitle: [
      { text: "Web launch.", checked: true },
      { text: "Whitepaper.", checked: true },
      { text: "Dex contracts.", checked: true },
    ],
  },
  {
    dot: "Stage 3",
    title: "July 2022",
    subtitle: [
      { text: "Swap pools MATIC/DENI launch.", checked: false },
      { text: "First Council: DAO voting event to create House.", checked: false },
      { text: "Stake $DENI on Houses.", checked: false },
    ],
  },
  {
    dot: "Stage 4",
    title: "Q3 2022",
    subtitle: [
      { text: "Man's House NFT collection.", checked: false },
      { text: "Collect resources with Heroes.", checked: false },
      { text: "Second Council: DAO voting event.", checked: false },
      { text: "First $DENI burn event.", checked: false },
      { text: "NFTs marketplace", checked: false },
      { text: "Contracts Audit", checked: false },
    ],
  },
  {
    dot: "Stage 5",
    title: "Q4 2022",
    subtitle: [
      { text: "Elves's NFT collection.", checked: false },
      { text: "Dwarf's NFT collection.", checked: false },
      { text: "City Building.", checked: false },
      { text: "Training armies.", checked: false },
      { text: "Map with Castles positions.", checked: false },
      { text: "More $DENI burn events.", checked: false },
    ],
  },
  {
    dot: "Stage 6",
    title: "Q4 2022",
    subtitle: [
      { text: "Scholarship system.", checked: false },
      { text: "Knights and Wizards.", checked: false },
      { text: "PvE battles.", checked: false },
      { text: "$DENI burn events.", checked: false },
    ],
  },
  {
    dot: "Stage 7",
    title: "Q1 2023",
    subtitle: [
      { text: "PvP battles.", checked: false },
      { text: "More $DENI burn events.", checked: false },
    ],
  },
  {
    dot: "",
    title: "2023 & Beyond",
    subtitle: [
      { text: "Unlock 100% $DENI from yield farming.", checked: false },
      { text: "Terrae development will be mostly community driven. Council events will be celebrated with frequency to decide protocol and game improvements.", checked: false },
      { text: "Launch of the Terrae Metaverse.", checked: false },
    ],
  },
];
