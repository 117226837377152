const MetamaskIcon = (props) => (
  <svg
    width={65}
    height={60}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: `scale(${window.outerWidth < 768 ? 0.5 : 1})` }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.143 19.975L0 9.625 3.19 0l20.511 7.7h17.097L61.31 0l-24.09 17.926.004.003L61.31 0l3.19 9.625-2.118 10.35L63.7 21l-2.018 1.55 1.545 1.2-2.019 1.85 1.271.925-2.916 3.425L64 43.7l-4.112 14.05-14.355-3.975-2.766 2.3L37.085 60H27.39l-5.658-3.925-2.791-2.3L4.61 57.75.522 43.7l4.412-13.75-2.916-3.425 1.27-.925-2.018-1.85 1.545-1.2L.773 21l1.37-1.025zm33.62 28.774h-7.027l-1.197.85-.673 5.6.623-.55h9.52l.648.55-.697-5.6-1.196-.85zm-8.698-8.65l-2.093-4.424L19.937 38l7.128 2.1zm17.52-2.1l-5.058-2.324-2.094 4.425 7.153-2.1z"
      fill="#C2C2C2"
      fillOpacity={0.8}
    />
  </svg>
);

export default MetamaskIcon;
