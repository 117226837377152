import { createTheme, responsiveFontSizes } from "@material-ui/core";

export const dark = responsiveFontSizes(createTheme({
  props: {
    MuiTooltip: {
      arrow: true,
      style: {
        pointerEvents: "all",
      },
    },
    MuiList: {
      dense: true,
      disablePadding: true,
    },
    MuiListItem: {
      dense: true,
      disableGutters: true,
    },
    MuiDivider: {
      style: {
        marginTop: 16,
        marginBottom: 16,
      },
    },
  },
  shape: {
    borderRadius: 10,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 5,
        fontWeight: 500,
        fontFamily: "Poppins",
      },
      contained: {
        minWidth: 170,
        padding: 10,
        "@media (max-width:600px)": {
          minWidth: 150,
        },
      },
      outlinedPrimary: {
        "&:hover": {
          color: "#000000",
          backgroundColor: "#e2a23b",
        },
      },
      textSecondary: {
        "&:hover": {
          color: "#e2a23b",
          backgroundColor: "transparent",
        },
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: "1rem",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 5,
        background: "rgba(18, 19, 20, 0.8)",
        color: "#919094",
      },
    },
  },
  typography: {
    // allVariants: {
    //   fontFamily: "Poppins",
    // },
    h1: {
      fontFamily: "Playfair Display",
      fontWeight: 700,
    },
    h2: {
      fontFamily: "Playfair Display",
      fontWeight: 700,
    },
    h3: {
      fontFamily: "Playfair Display",
      fontWeight: 700,
    },
    h4: {
      fontFamily: "Playfair Display",
      fontWeight: 700,
    },
    h5: {
      fontFamily: "Playfair Display",
    },
    h6: {
      fontFamily: "Poppins",
    },
    subtitle1: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: 16,
    },
    subtitle2: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: 12,
    },
    button: {
      fontFamily: "Poppins",
      textTransform: "capitalize",
      fontWeight: 500,
    },
  },
  palette: {
    type: "dark",
    info: {
      main: "#7289da",
    },
    error: {
      main: "#e03f54",
    },
    warning: {
      main: "#ff8900",
    },
    success: {
      main: "#4caf50",
    },
    text: {
      primary: "#e2a23b",
      secondary: "#e9dec3",
      subtitle: "#919094",
    },
    primary: {
      main: "#e2a23b",
      contrastText: "#000000",
    },
    secondary: {
      main: "#e9dec3",
    },
    background: {
      default: "#121314",
      paper: "#424549",
    },
    divider: "#eee",
    values: "#919094",
  },
}));
