import React, { useState } from "react";
import { Button } from "@material-ui/core";
import DialogForm from "./DialogForm";

const ConfirmationDialog = ({
  cancelLabel, continueLabel,
  onContinue, onCancel,
  callback, onClose,
  actions,
  ...rest
}) => {
  const [loading, setLoading] = useState();

  const wrapAction = async (action) => {
    setLoading(true);
    if (action) await action();
    setLoading(false);
    if (onClose) onClose();
  };

  const wrappedOnClose = () => {
    if (onCancel) onCancel();
    onClose();
  };

  const defaultActions = [
    { text: continueLabel || "Ok", onClick: () => wrapAction(onContinue), variant: "contained", color: "primary" },
  ];

  return (
    <DialogForm
      cross
      open
      actions={(actions || defaultActions).map(({ text, onClick, variant, color }, index) => (
        <Button
          onClick={onClick}
          disabled={loading}
          variant={variant}
          color={color}
          key={index}
        >
          {text}
        </Button>
      ))}
      onClose={wrappedOnClose}
      {...rest}
    />
  );
};

export default ConfirmationDialog;
