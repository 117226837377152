import { useState } from "react";
import { Box, Button, Grid, Typography, Fade, Slide, Container, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { InView } from "react-intersection-observer";
import { withMainLayout } from "hocs/withMainLayout";
import LogoMobile from "assets/svgr/logotype/LogoSmall";
import DividerTop from "assets/svgr/dividers/DividerTop";
import MetamaskIcon from "assets/svgr/companies/MetamaskIcon";
import RatherIcon from "assets/svgr/companies/RatherIcon";
import PolygonIcon from "assets/svgr/companies/PolygonIcon";
import UniswapIcon from "assets/svgr/companies/UniswapIcon";
import { vhLess } from "utils";
import VerticalArragement from "components/VerticalArragement";
import links from "links";
import Team from "components/Team";
import Roadmap from "./sections/roadmap/Roadmap";
import Banner from "./sections/banner/Banner";
import DecentralizedFinance from "./sections/finance/DecentralizedFinance";
import WelcomeDialog from "./sections/welcomeDialog/WelcomeDialog";
import Houses from "./sections/houses/Houses";
import Newsletter from "./sections/newsletter/Newsletter";

const useStyles = makeStyles((theme) => ({
  mainBackgroundImage: {
    top: 0,
    left: 0,
    background: "linear-gradient(180deg, #121314 0%, #252b4203 88.5%)",
    "& img": {
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      objectFit: "cover",
      filter: "brightness(0.75)",
      zIndex: -2,
      objectPosition: "center",
    },
  },
  section1: {
    height: vhLess(125),
  },
  section2: {
    backgroundColor: "#121314",
  },
  section3: {
    backgroundColor: "#2d2d35",
  },
  section5: {
    backgroundColor: "#000",
  },
  containerImg: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 20,
    "&.avatarWarriors": {
      justifyContent: "flex-start",
    },
    "& img": {
      width: "100%",
      maxWidth: 600,
      [theme.breakpoints.down("xs")]: {
        maxWidth: 400,
      },
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center!important",
    },
  },
}));

const Landing = () => {
  const [openDialog, setOpenDialog] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles({ isMobile });

  const goDiscord = () => window.open(links.DISCORD, "_blank");
  const goWebapp = () => window.open(links.WEBAPP, "_blank");

  const goGameplayDocs = () => window.open(links.GAMEPLAY_DOCS, "_blank");
  const goBattlesDocs = () => window.open(links.BATTLE_DOCS, "_blank");

  const handleDialog = () => setOpenDialog(false);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" flex={1}>
      <Box position="absolute" height={1} width={1} className={classes.mainBackgroundImage}>
        <img src="/background.jpg" alt="landing background" />
      </Box>
      <Grid
        container
        flex={1}
        alignItems="flex-start"
        alignContent="center"
        className={classes.section1}
      >
        <Banner goWebapp={goDiscord} />
        <WelcomeDialog
          goDiscord={goDiscord}
          isSm={isSm}
          isMd={isMd}
          openDialog={openDialog}
          handleDialog={handleDialog}
        />
      </Grid>
      <Grid
        container
        flex={1}
        justifyContent="center"
        className={classes.section2}
      >
        <InView triggerOnce delay={500} threshold={1}>
          {({ inView, ref }) => (
            <Grid ref={ref} item xs={12} lg={8} xl={6} style={{ maxWidth: "100%" }}>
              <Fade in={inView} {...(inView ? { timeout: 1000 } : {})}>
                <Box display="flex" flexDirection={isMobile ? "column" : "row"} textAlign="left" alignItems="center" marginY={8} marginX={2} justifyContent="center">
                  <Box mr={isMobile ? 0 : 4} mb={isMobile ? 3 : 4}><LogoMobile style={{ transform: "scale(1.25)" }} /></Box>
                  <Box ml={isMobile ? 0 : 1}>
                    <Typography variant="h5" color="secondary">
                      Terrae is a GameFi platform in which $DENI token
                      gets real value from protocol,
                      and game winners earn rewards from DEX
                    </Typography>
                  </Box>
                </Box>
              </Fade>
            </Grid>
          )}
        </InView>
      </Grid>
      <Grid
        container
        flex={1}
        justifyContent="center"
        className={classes.section3}
        id="protocol"
      >
        <DecentralizedFinance />
      </Grid>
      <Container>
        <VerticalArragement py={12} spacing={10}>
          <InView triggerOnce delay={500} threshold={0.2}>
            {({ inView, ref }) => (
              <Box ref={ref}>
                <Slide in={inView} direction="up" {...(inView ? { timeout: 500 } : {})}>
                  <Grid container direction={isSm ? "column-reverse" : "row"}>
                    <Grid item xs={12} md={5}>
                      <Typography variant="h4" paragraph>
                        Build your City and Rise Your Own Empire
                      </Typography>
                      <Typography variant="body2" color="secondary">
                        As a new player, you will be facing multiple challenges to gain experience
                        and level your character. You will be able to get your land on Terrae
                        to mine naturals resources, build your economy and your city,
                        train your troops, improve your technologies and be ready to fight.

                      </Typography>
                      <Box mt={2}>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={goGameplayDocs}
                        >
                          View more
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={7} className={classes.containerImg}>
                      <picture>
                        <source media="(max-width:600px)" srcSet="images/warriors-mobile.png" />
                        <img src="images/warriors.png" alt="warriors" />
                      </picture>
                    </Grid>
                  </Grid>
                </Slide>
              </Box>
            )}
          </InView>
          <InView triggerOnce delay={500} threshold={0.2}>
            {({ inView, ref }) => (
              <Box ref={ref}>
                <Slide in={inView} direction="up" {...(inView ? { timeout: 500 } : {})}>
                  <Grid container>
                    <Grid item xs={12} md={7} className={`${classes.containerImg} avatarWarriors`}>
                      <picture>
                        <img src="images/avatar-warriors.png" alt="avatar warriors" />
                      </picture>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Typography variant="h4" paragraph>
                        Collet NFTs to Build your Army
                      </Typography>
                      <Typography variant="body2" color="secondary">
                        Players build their armies by combining troops
                        to deploy the strategies they consider necessary in battles.
                        Leaders improve the stats of the armies,
                        giving them higher resistance or attack,
                        for example. Leaders are heroes, knights or wizards NFTs.
                        The released battles can be of two types: PVE and PVP.
                      </Typography>
                      <Box mt={2}>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={goBattlesDocs}
                        >
                          View more
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Slide>
              </Box>
            )}
          </InView>
        </VerticalArragement>
      </Container>
      <Roadmap isMobile={isMobile} />
      <VerticalArragement className={classes.section3} style={{ width: "100%" }} pb={10} pt={5}>
        <Houses isMd={isMd} isSm={isSm} />
      </VerticalArragement>
      <VerticalArragement width={1}>
        <Container maxWidth="lg">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="fit-content"
            width={1}
            mb={8}
            mt={11}
          >
            <Typography variant="h4" paragraph>
              The Team
            </Typography>
            <DividerTop />
            <Team />
          </Box>
        </Container>
      </VerticalArragement>
      <Grid
        container
        flex={1}
        justifyContent="center"
        className={classes.section5}
      >
        <InView triggerOnce delay={500} threshold={1}>
          {({ inView, ref }) => (
            <Grid ref={ref} item xs={12} lg={8} xl={6}>
              <Fade in={inView} {...(inView ? { timeout: 1000 } : {})}>
                <Box display="flex" flexDirection="row" justifyContent="space-around" alignItems="center" marginY={8} marginX={2}>
                  <MetamaskIcon />
                  <RatherIcon />
                  <PolygonIcon />
                  <UniswapIcon />
                </Box>
              </Fade>
            </Grid>
          )}
        </InView>
      </Grid>
      <Newsletter />
    </Box>
  );
};

export default withMainLayout(Landing);
