import { Box, Grid, Typography, Slide, Container, makeStyles } from "@material-ui/core";
import DividerTop from "assets/svgr/dividers/DividerBottom";
import TimeLine from "components/TimeLine";
import InView from "react-intersection-observer";
import { timelineData } from "./utils";

const useStyles = makeStyles(() => ({
  roadmapBackgroundImage: {
    "& img": {
      position: "absolute",
      height: "100%",
      width: "100%",
      top: 0,
      left: 0,
      filter: "brightness(0.40)",
      objectFit: "cover",
      objectPosition: "bottom left",
    },
  },
  section4: {
    position: "relative",
    backgroundColor: "#121314",
    minHeight: 750,
  },
}));

const Roadmap = ({ isMobile }) => {
  const classes = useStyles({ isMobile });

  return (
    <Grid
      container
      flex={1}
      justifyContent="center"
      className={classes.section4}
      id="roadmap"
    >
      <Box position="absolute" height={1} width={1} className={classes.roadmapBackgroundImage}>
        <img src="/roadmap.png" alt="roadmap background" />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="fit-content"
        width={1}
        mb={8}
        mt={11}
        zIndex={5}
      >
        <Typography variant="h4" paragraph>
          Roadmap
        </Typography>
        <DividerTop />
      </Box>
      <InView triggerOnce delay={500}>
        {({ inView, ref }) => (
          <Container ref={ref} maxWidth="lg" className={classes.roadmapContainer}>
            <Slide in={inView} direction="up" {...(inView ? { timeout: 500 } : {})}>
              <Box height={1} pb={5}>
                <TimeLine data={timelineData} />
              </Box>
            </Slide>
          </Container>
        )}
      </InView>
    </Grid>
  );
};

export default Roadmap;
