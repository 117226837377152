import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  title: {
    marginRight: 30,
  },
}));

const Dialogs = ({
  open,
  title,
  cross,
  subtitle,
  actions,
  onClose,
  children,
  contentClasses,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      onClose={onClose}
      open={open}
      {...rest}
    >
      {
        onClose && cross
        && (
          <IconButton
            className={classes.closeButton}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        )
      }
      <DialogTitle className={classes.title}>
        <Typography variant="h5" className="bold">
          {title}
        </Typography>
        <Typography color="textSecondary">
          {subtitle}
        </Typography>
      </DialogTitle>
      <DialogContent classes={contentClasses}>
        {children}
      </DialogContent>
      <DialogActions>
        {actions}
      </DialogActions>
    </Dialog>
  );
};

export default Dialogs;
